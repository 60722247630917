namespace gotoAndPlay {

    export interface IVideoSettings {
        InitClass?: string;
        ButtonClass?: string;
        IframeClass?: string;
        PlayingClass?: string;
    }

    export const VideoSettings = {
        InitClass: 'video',
        ButtonClass: 'video__btn',
        IframeClass: 'video__iframe',
        PlayingClass: 'is-playing',
    };

    export class Video {

        settings: IVideoSettings;
        element: JQuery;
        button: JQuery;
        iframe: JQuery;

        constructor(target: HTMLElement, settings: IVideoSettings = {}) {
            this.settings = jQuery.extend(VideoSettings, settings) as IVideoSettings;
            this.element = $(target);
            this.button = this.element.find(this.settings.ButtonClass);
            this.iframe = this.element.find(this.settings.IframeClass).length ? this.element.find(this.settings.IframeClass) : this.element.find('iframe');
        }

        init(): void {
            this.play();
        }

        play(): void {
            console.log(this.settings);
            this.element.addClass(this.settings.PlayingClass);
        }

        stop(): void {
            if (this.element.hasClass(this.settings.PlayingClass)) {
                this.element.removeClass(this.settings.PlayingClass);
                this.iframe.attr('src', '');
                this.iframe.removeClass('lazyloaded').addClass('lazyload');
            }
        }

        /* reset(target: JQuery): void {
            if (target && $.contains(target, this.element)) {
                this.stop();
            }
        } */
    }

    function onClick(event: JQueryEventObject): void {
        event.preventDefault();
        const video: Video = new Video(this);
        video.init();
    }

    function onInit(): void {
        $(document).on('click', '.' + VideoSettings.InitClass, onClick);
    }

    $(document).ready(onInit);
}
