// import('import/js/jquery.serialize-object.js');

namespace gotoAndPlay {

    export interface IEditableSettings {
        ControlClass?: string;
        CloseClass?: string;
        SaveClass?: string;
    }

    export const EditableSettings = {
        ControlClass: 'js-edit',
        CloseClass: 'js-close-editable',
        SaveClass: 'js-save-editable',
    };

    export class Editable {

        settings: IEditableSettings;
        element: JQuery;

        constructor(target: HTMLElement, settings: IEditableSettings = {}) {
            this.settings = jQuery.extend(EditableSettings, settings) as IEditableSettings;
            this.element = $(target);
        }

        getParent() {
            return this.element.parents('.editable');
        }

        getData() {
            return Helpers.objectifyForm(this.getParent());
        }

        open(): void {
            this.getParent().addClass('is-editing');
            initDatePickers();
            this.getParent().find('input:eq(0)').focus();
        }

        close(): void {
            this.getParent().removeClass('is-editing');
        }

        save(): void {
            switch (this.element.data('action')) {
                case 'update-profile':
                    if (Validation.validate(this.getParent())) {
                        Ajax.post({
                            action: 'update-profile',
                            data: this.getData(),
                        }).then((result) => {
                            this.close();
                            if (result.success) {
                                this.getParent().find('.editable__value-content').text(this.getParent().find('input:eq(0)').val());
                            }
                        });
                    }
                    break;

                case 'update-location':
                case 'add-location':
                    if (Validation.validate(this.getParent())) {
                        Ajax.post({
                            action: 'add-location',
                            data: this.getData(),
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                    break;

                case 'update-company':
                case 'add-company':
                    if (Validation.validate(this.getParent())) {
                        Ajax.post({
                            action: 'add-company',
                            data: this.getData(),
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                    break;

                default:
                    this.close();
                    break;
            }
        }
    }

    function onControlClick(event: JQueryEventObject): void {
        event.preventDefault();
        const editable: Editable = new Editable(this);
        editable.open();
    }

    function onCloseClick(event: JQueryEventObject): void {
        event.preventDefault();
        const editable: Editable = new Editable(this);
        editable.close();
    }

    function onSaveClick(event: JQueryEventObject): void {
        event.preventDefault();
        const editable: Editable = new Editable(this);
        editable.save();
    }

    function onSubmit(event: JQueryEventObject) {
        event.preventDefault();
        const editable: Editable = new Editable($(event.currentTarget).find('.' + EditableSettings.SaveClass).get(0));
        editable.save();
    }

    function onInit(): void {
        $(document).on('click', '.js-editable-new-add', function (event) {
            event.preventDefault();
            $(this).hide();
            $(this).parent().find('.js-editable-new').removeClass('h-hidden');
        });
        $(document).on('click', '.js-close-editable-new', function (event) {
            event.preventDefault();
            $(this).parents('.js-editable-new').parent().find('.js-editable-new-add').show();
            $(this).parents('.js-editable-new').addClass('h-hidden');
        });
        $(document).on('change', '.js-editable-profile-list input', function (event) {
            let data = {};
            data[$(this).attr('name')] = $(this).is('checked');
            Ajax.post({
                action: 'update-profile',
                data: data,
            });
        });
        $(document).on('click', '.' + EditableSettings.ControlClass, onControlClick);
        $(document).on('click', '.' + EditableSettings.CloseClass, onCloseClick);
        $(document).on('click', '.' + EditableSettings.SaveClass, onSaveClick);
        $(document).on('submit', 'form.editable', onSubmit);
    }

    $(document).ready(onInit);
}
