namespace gotoAndPlay {

    interface ITrainingHeaderSettings {
        cardClass?: string;
    }

    /**
     * @brief      Nav toggle class
     */
    export class TrainingHeader {

        element: JQuery;
        siteBody: JQuery;
        settings: ITrainingHeaderSettings;
        document: JQuery;
        window: JQuery;
        cardSticky: boolean;
        cardHidden: boolean;
        lastY: number;

        constructor(target: HTMLElement) {
            this.settings = {
                cardClass: 'training-header__card',
            };

            this.element = $(target);
            this.siteBody = $('body');
            this.document = $(document);
            this.window = $(window);
            this.lastY = this.window.scrollTop();
            this.cardSticky = false;
            this.cardHidden = false;

            this.init();
        }

        init(): void {
            this.window.on('scroll', this.scrollHead.bind(this));
        }

        scrollHead(): void {
            // get current scroll position
            const currY = this.window.scrollTop();
            const windowWidth = this.window.width();
            const containerWidth = $('.h-container').width();
            const cardTop = $('.training-header').offset().top;
            const freeSpace = (windowWidth - containerWidth) / 2;
            const authorBox = $('.author__content').offset().top;
            const cHeight = $('.training-header__card').height();
            const authorOffset = authorBox - cHeight;

            if (currY + 52 >= cardTop && !this.cardSticky) {
                this.cardSticky = true;
                const cardHeight = $('.training-header__card').height();
                $('.training-header__card').css({right: freeSpace, height: cardHeight});
                $('.training-header__card').addClass('is-sticky');
            }

            if (currY + 52 < cardTop) {
                this.cardSticky = false;
                $('.training-header__card').removeClass('is-sticky');
            }

            if (currY >= authorOffset && !this.cardHidden) {
                this.cardHidden = true;
                $('.training-header__card').addClass('is-hidden');
            }

            if (currY < authorOffset) {
                this.cardHidden = false;
                $('.training-header__card').removeClass('is-hidden');
            }

            // update last scroll position to current position
            this.lastY = currY;
        }

        static updateModal() {
            let people = Helpers.numeric($('.js-training-people select').val());
            let price = Helpers.numeric($('.js-training-price').data('price'));
            $('.js-training-price').text(Helpers.numberFormat(price * people));
        }
    }

    // Init on document ready
    $(function () {
        $(document).on('change', '.js-training-people', () => {
            TrainingHeader.updateModal();
        });
        $('.training-header').each(function () {
            const trainingHeader = new TrainingHeader(this);
        });
    });
}
