namespace gotoAndPlay {
    export class Cart {

        window: any;
        element: JQuery;
        form: JQuery;
        cpnSubmit: JQuery;
        formSubmit: JQuery;
        removeProduct: JQuery;
        giftField: JQuery;
        debounce: number;
        doingAjax: boolean;
        eventTarget: JQuery;
        productCount: JQuery;
        private timeout;
        private xhr;

        constructor(target: HTMLElement) {
            this.window        = window;
            this.element       = $(target);
            this.form          = this.element.closest('form');
            this.cpnSubmit     = this.form.find('input[name="apply_coupon"]');
            this.formSubmit    = this.form.find('input[name="update_cart"]');
            this.productCount  = this.element.find('input.qty');
            this.removeProduct = this.element.find('.js-cart-remove a');
            this.giftField     = this.element.parents('form').find('.js-gift-field');

            this.bindEvents();
            this.checkCodes();
        }

        bindEvents() {
            if (this.form.length) {
                this.form.on('change', 'input', this.updateCart.bind(this));
                $('.js-cart-remove a').bindFirst('click', function(event) {
                    if ($('.woocommerce-cart-form__cart-item').length == 1) {
                        event.stopImmediatePropagation();
                    }
                });
            }
            if (this.element.hasClass('cart--popout')) {
                // this.productCount.on('change', this.updateCartCount.bind(this));
                // this.removeProduct.on('click', this.removeFormCart.bind(this));
                this.element.on('change', 'input.qty', this.updateCartCount.bind(this));
                this.element.on('click', '.js-cart-remove a', this.removeFormCart.bind(this));
            }

            $( document.body ).on( 'updated_cart_totals', function(event) {
                initCheckfields();
            });
        }

        checkCodes() {
            if (this.window.addedCode) {
                if (!$('.shop__summary .js-giftcard').length) {
                    let giftError = $('.woocommerce-error li:first-child').text();
                    this.giftField.find('.js-textfield-error').html(giftError);
                    $('#giftcardform').addClass('is-visible');
                    this.giftField.find('.icon').addClass('h-hidden');
                    this.giftField.find('.icon.js-icon-error').removeClass('h-hidden');
                    this.giftField.removeClass('is-done').addClass('is-invalid');
                }
                this.window.addedCode = false;
            }
        }

        updateCart(event: JQueryEventObject) {
            clearTimeout(this.debounce);
            if ($(event.target).is('input')) {
                this.eventTarget = $(event.target);
            }
            this.debounce = setTimeout(this.submitCart.bind(this), 600);
        }

        submitCart() {
            if (this.eventTarget.prop('name') == 'coupon_code') {
                this.window.addedCode = true;
                this.cpnSubmit.click();
            } else {
                this.formSubmit.click();
            }
        }

        updateCartCount(event: JQueryEventObject) {
            let input = $(event.currentTarget);

            if (input.data('id')) {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                this.timeout = setTimeout(() => {
                    this.element.addClass('is-loading');
                    if (this.xhr) {
                        this.xhr.abort();
                    }
                    this.xhr = Ajax.post({
                        action: 'add-to-cart',
                        data: input.data('id'),
                        set: input.val(),
                    });
                    this.xhr.then((result) => {
                        if (result.minicart) {
                            updateMinicart(result.minicart, result.cartCount);
                        }
                        this.element.removeClass('is-loading');
                    });
                }, 500);
            }
        }

        removeFormCart(event: JQueryEventObject) {
            event.preventDefault();

            let elem = $(event.currentTarget);
            this.element.addClass('is-loading');
            if (!this.doingAjax) {
                this.doingAjax = true;
                Ajax.post({
                    action: 'remove-cart-item',
                    data: elem.attr('href'),
                }).then((result) => {
                    if (result.minicart) {
                        updateMinicart(result.minicart, result.cartCount);
                    }
                    this.doingAjax = false;
                    this.element.removeClass('is-loading');
                }).always(() => {
                    this.doingAjax = false;
                    this.element.removeClass('is-loading');
                });
            }
            return false;
        }
    }

    export function updateMinicart(minicart: string, cartCount: number, activate: boolean = false) {
        $('.js-minicart').html(minicart);
        if (cartCount == 0) {
            $('.js-minicart-count').text('');
        } else {
            $('.js-minicart-count').text(cartCount);
        }

        $(document).trigger('gtap.updatedCart');
        $(document).trigger('updated_cart_totals');
        if (activate) {
            if ($(window).width() >= 1024) {
                $('body').addClass('has-overlay');
                $('.js-minicart').addClass('is-active');
            }
        }
    }

    $(function() {
        $(document).on('updated_cart_totals', (event: JQueryEventObject) => {
            $('.cart').each(function() {
                const cart = new Cart(this);
                initNumberInputs(this);
            });
        });

        $(document).on('wc_fragments_refreshed', (event) => {
            initModaals();
        });

        // add to cart in modal, used on cart page
        $(document).on('click', 'button.js-modal-add-to-cart', function(event) {
            event.preventDefault();
            if ($(this).data('id')) {
                window.location.href = window.location.href + '?add-to-cart=' + $(this).data('id');
            }
        });

        if ($('.js-minicart').length) {
            Ajax.get(gotoAndPlay.rootPath + '?removed_item=1').then((result) => {
                if (result.minicart) {
                    updateMinicart(result.minicart, result.cartCount);
                }
            });
        } else {
            $('.cart').each(function() {
                const cart = new Cart(this);
            });
        }
    });
}
