namespace gotoAndPlay {

    export enum AccordionAnimationType {
        Slide,
        FadeAndSlide,
    }

    export enum AccordionAnimationDirection {
        In,
        Out,
    }

    export interface IAccordionSettings {
        OpenClass?: string;
        AccordionClass?: string;
        GroupClass?: string;
        ControlClass?: string;
        ContentClass?: string;
        AnimationSlideTime?: number;
        AnimationFadeTime?: number;
        ScrollDelay?: number;
        AnimationSlideEase?: string;
        AnimationFadeEase?: string;
        AnimationType?: AccordionAnimationType;
    }

    export const AccordionSettings = {
        AccordionClass: 'js-accordion',
        AnimationFadeEase: 'swing',
        AnimationFadeTime: 500,
        AnimationSlideEase: 'swing',
        AnimationSlideTime: 500,
        AnimationType: AccordionAnimationType.Slide,
        ContentClass: 'js-accordion-content',
        ControlClass: 'js-accordion-control',
        GroupClass: 'js-accordion-group',
        OpenClass: 'is-open',
        ScrollDelay: 500,
    };

    export class Accordion {

        settings: IAccordionSettings;
        element: JQuery;

        constructor(target: HTMLElement, settings: IAccordionSettings = {}) {
            this.settings = jQuery.extend(AccordionSettings, settings) as IAccordionSettings;
            this.element = $(target);
        }

        getAccordion(): JQuery {
            let accordion: JQuery;
            if (this.element.hasClass(this.settings.AccordionClass)) {
                accordion = this.element.addClass(this.settings.AccordionClass);
            } else {
                accordion = this.element.parents('.' + this.settings.AccordionClass);
            }
            return accordion.length ? $(accordion.get(0)) : accordion;
        }

        toggle(accordion: JQuery = null): void {
            if (!accordion) {
                accordion = this.getAccordion();
            }
            if (accordion.length) {
                if (accordion.hasClass(this.settings.OpenClass)) {
                    this.close(accordion);
                } else {
                    this.open(accordion, true);
                }
            }
        }

        open(accordion: JQuery = null, recursive: boolean = false, initialLoad: boolean = false): void {
            if (!accordion) {
                accordion = this.getAccordion();
            }
            if (accordion.length) {
                this.closeOpen(accordion, recursive);
                const parent: JQuery = accordion.parents('.' + this.settings.AccordionClass);
                if (parent.length) {
                    const parentAccordion: Accordion = new Accordion(parent.get(0));
                    parentAccordion.open(null, true, initialLoad);
                }
                accordion.addClass(this.settings.OpenClass);
                const content: JQuery = accordion.find('.' + this.settings.ContentClass);
                if (content.length) {
                    if (!recursive) {
                        setTimeout(() => {
                            if (!Helpers.isOnScreen($(content.get(0)))) {
                                Helpers.scrollToTarget(accordion);
                            }
                        }, this.settings.ScrollDelay);
                    }
                }
                if (!recursive && !initialLoad) {
                    this.animate(AccordionAnimationDirection.In, accordion);
                }
            }
        }

        close(accordion: JQuery = null, recursive: boolean = false): void {
            if (!accordion) {
                accordion = this.getAccordion();
            }
            if (accordion.length) {
                if (!recursive) {
                    this.animate(AccordionAnimationDirection.Out, accordion, () => {
                        accordion.removeClass(this.settings.OpenClass);
                    });
                } else {
                    accordion.removeClass(this.settings.OpenClass);
                }
            }
        }

        closeOpen(accordion: JQuery = null, recursive: boolean = false): void {
            if (!accordion) {
                accordion = this.getAccordion();
            }
            let group: JQuery = accordion.parents('.' + this.settings.GroupClass);
            if (group.length) {
                group = $(group.get(0));
                const open: JQuery = group.find('> .' + this.settings.AccordionClass + '.' + this.settings.OpenClass);
                open.each((index: number, element: HTMLElement) => {
                    this.close($(element), recursive);
                });
            }
        }

        animate(type: AccordionAnimationDirection, accordion: JQuery = null, onComplete: () => void = null): void {
            if (!accordion) {
                accordion = this.getAccordion();
            }
            if (accordion.length) {
                const triggerOnComplete: () => void = () => {
                    content.removeAttr('style');
                    if (onComplete) {
                        onComplete();
                    }
                };
                let content: JQuery = accordion.find('.' + this.settings.ContentClass);
                if (content.length) {
                    const contentHeight: string = content.get(0).offsetHeight + 'px';
                    content = content.length ? $(content.get(0)) : content;
                    switch (this.settings.AnimationType) {
                        case AccordionAnimationType.Slide:
                            switch (type) {
                                case AccordionAnimationDirection.In:
                                    content.stop().css({
                                        height: 0,
                                    }).animate({
                                        height: contentHeight,
                                    }, this.settings.AnimationFadeTime, this.settings.AnimationFadeEase, triggerOnComplete);
                                    break;
                                case AccordionAnimationDirection.Out:
                                    content.stop().animate({
                                        height: 0,
                                    }, this.settings.AnimationSlideTime, this.settings.AnimationSlideEase, triggerOnComplete);
                                    break;
                            }
                            break;
                        case AccordionAnimationType.FadeAndSlide:
                            switch (type) {
                                case AccordionAnimationDirection.In:
                                    content.stop().css({
                                        height: 0,
                                        opacity: 0,
                                    }).animate({
                                        height: contentHeight,
                                    }, this.settings.AnimationSlideTime, this.settings.AnimationSlideEase, () => {
                                        content.stop().animate({
                                            opacity: 1,
                                        }, this.settings.AnimationFadeTime, this.settings.AnimationFadeEase, triggerOnComplete);
                                    });
                                    break;
                                case AccordionAnimationDirection.Out:
                                    content.stop().animate({
                                        opacity: 0,
                                    }, this.settings.AnimationFadeTime, this.settings.AnimationFadeEase, () => {
                                        content.stop().animate({
                                            height: 0,
                                        }, this.settings.AnimationSlideTime, this.settings.AnimationSlideEase, triggerOnComplete);
                                    });
                                    break;
                            }
                            break;
                    }
                }
            }
        }
    }

    function onControlClick(event: JQueryEventObject): void {
        event.preventDefault();
        const accordion: Accordion = new Accordion(this);
        accordion.toggle();
    }

    function onLoadHashCheck(): void {
        if (window.location.hash && $(window.location.hash).length && $(window.location.hash).hasClass(AccordionSettings.AccordionClass)) {
            const accordion: Accordion = new Accordion($(window.location.hash).get(0));
            accordion.open(null, false, true);
        }
    }

    function onInit(): void {
        $(document).on('click', '.' + AccordionSettings.ControlClass, onControlClick);
        onLoadHashCheck();
    }

    $(document).ready(onInit);
}
