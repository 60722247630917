namespace gotoAndPlay {
    $.fn.bindFirst = function (name, fn) {
        this.on(name, fn);
        this.each(function () {
            let handlers = ($ as any)._data(this, 'events')[name.split('.')[0]];
            let handler = handlers.pop();
            handlers.splice(0, 0, handler);
        });
    };

    export class Checkout {

        element: JQuery;
        addressSelect: JQuery;
        addresses: any;
        tabControl: JQuery;

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.addressSelect = this.element.find('select[name="user_address"]');
            this.tabControl = this.element.find('.js-tabs-control');

            let win: any = window;
            if (win.billingValues) {
                this.addresses = JSON.parse(win.billingValues);
            } else {
                this.addresses = {};
            }

            this.bindEvents();
        }

        bindEvents() {
            this.addressSelect.on('change', this.updateAddresses.bind(this));
            this.tabControl.on('click', this.updateActiveFields.bind(this));
        }

        displayLhvModal() {
            if ($('.js-lhv-modal')) {
                $('.js-lhv-modal').click();
            }
        }

        updateAddresses(event: JQueryEventObject) {
            if (this.addresses) {
                let selected = this.addresses[this.addressSelect.val()];
                for (let addr in selected) {
                    let element;
                    if (addr == 'billing_country') {
                        element = this.element.find('.select-wrapper:visible [name="' + addr + '"]');
                        if (element.length) {
                            element.material_select('destroy');
                            element.find('option:disabled').removeAttr('selected');
                            element.find('option:selected').removeAttr('selected');
                            element.val(selected[addr]).trigger('change');
                            element.material_select();
                        }
                    } else {
                        element = this.element.find('[name="' + addr + '"]:visible');
                        if (element.length) {
                            element.val(selected[addr]).trigger('change');
                        }
                    }
                }
                this.element.trigger('change');
            }
        }

        updateActiveFields(event: JQueryEventObject) {
            let elem = $(event.target).closest('.js-tabs-control');
            let active = elem.data('form-type');
            let userInputs = $('.js-billing-user input, .js-billing-user select');
            let businessInputs = $('.js-billing-business input, .js-billing-business select');

            userInputs.each((i, el) => {
                let input = $(el);
                if (active == 'business') {
                    input.prop('name', input.prop('name') + '-disabled');
                } else {
                    input.prop('name', input.prop('name').replace('-disabled', ''));
                }
            });

            businessInputs.each((i, el) => {
                let input = $(el);
                if (active == 'user') {
                    input.attr('name', input.attr('name') + '-disabled');
                } else {
                    input.attr('name', input.attr('name').replace('-disabled', ''));
                }
            });

        }

        static hideUserFields() {
            let shipping = $('.woocommerce-checkout-shipping input[type="radio"]:checked').val();
            let billingFields = $('.js-billing-user, .js-billing-business').find('.js-custom-shipping-display');
            billingFields.removeClass('h-hidden');

            if (typeof shipping === 'undefined') {
                return false;
            }

            let shippingTag = shipping.split(':')[0];

            billingFields.each((i, el) => {
                let elem = $(el);
                let toHide = elem.find('[data-shipping-hide]').data('shipping-hide');
                let input = elem.find('input, select');
                if (toHide.indexOf(shippingTag) !== -1 ) {
                    elem.addClass('h-hidden');
                } else {
                    if (input.attr('name') != 'user_address' && !input.val()) {
                        elem.find('.form-textfield').addClass('is-invalid');
                    }
                }
            });
        }
    }

    $(function () {
        $('form.checkout, form.comment-form').bindFirst('submit', function (event) {
            if (!Validation.validate($(this))) {
                event.preventDefault();
                event.stopImmediatePropagation();
            }
        });

        $(document).on('updated_checkout', (event) => {
            Checkout.hideUserFields();
            initModaals();
        });

        $(document).on('update_checkout', (event) => {
            $('.woocommerce-checkout-shipping').block({
                message: null,
                overlayCSS: {
                    background: '#fff',
                    opacity: 0.6,
                },
            });
        });

        $('.checkout').each(function () {
            const checkout = new Checkout(this);
        });
    });
}
