// import('import/js/jquery.fancybox.js');

namespace gotoAndPlay {

    $(function () {
        $('.lightbox__reel-link').fancybox({
            afterShow: function(instance, current) {
                console.log('AFTERSHOW  -> ', instance, '  -> ', current);
                initReel(current);
            },
        });
    });

}
