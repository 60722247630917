namespace gotoAndPlay {
    export class SingleHero {

        element: JQuery;
        cart: JQuery;
        variations: JQuery;
        cartSubmit: JQuery;
        doingAjax: boolean;

        constructor(target: HTMLElement) {
            this.element    = $(target);
            this.cart       = this.element.find('form.product-poster__form');
            this.variations = this.cart.find('.js-product-attr');
            this.cartSubmit = this.cart.find('button[type="submit"]');

            this.bindEvents();
            this.updateVariation();
        }

        bindEvents() {
            this.cart.on('submit', this.addToCart.bind(this));
        }

        addToCart(event: JQueryEventObject) {
            event.preventDefault();

            this.element.addClass('is-loading');
            $('.js-minicart .cart').addClass('is-loading');
            if (!this.doingAjax) {
                const formData          = this.cart.serializeObject();
                // object serialize dash fix
                formData['add-to-cart'] = this.cart.find('[name="add-to-cart"]').val();
                this.doingAjax          = true;
                Ajax.post(formData, window.location.href).done((result) => {
                    setTimeout(() => {
                        this.cartSubmit.removeClass('is-triggered');
                    }, 2000);
                    if (result.minicart) {
                        updateMinicart(result.minicart, result.cartCount, true);
                    }
                }).fail((error) => {
                    console.log(error);
                }).always((result) => {
                    this.doingAjax = false;
                    this.element.removeClass('is-loading');
                    $('.js-minicart .cart').removeClass('is-loading');
                });
            }

        }

        updateVariation(event?: JQueryEventObject) {
            let variation = this.variations.find('input:checked').data('variation-id');
            this.cart.find('input[name="variation_id"]').val(variation);

        }
    }

    $(function() {
        $('.single-hero__info').each(function() {
            const poster = new SingleHero(this);
        });
    });

}
