namespace gotoAndPlay {

    /**
     * Handle the button's click effect
     *
     * @brief      Button class
     */
    export class Button {

        element: JQuery;

        constructor(target: HTMLElement) {
            this.element = $(target);
        }

    }

    /**
     * Position the click effect's circle shape where the mouse is pointed
     * and add the transition class
     */
    function onMousedown(event: JQueryEventObject = null): void {
        const button: Button = new Button(this);

        const pageX     = event.pageX ? event.pageX : (event as any).originalEvent.touches[0].pageX;
        const pageY     = event.pageY ? event.pageY : (event as any).originalEvent.touches[0].pageY;
        const MousePosX = pageX - button.element.offset().left;
        const MousePosY = pageY - button.element.offset().top;

        button.element.find('.button__bubble').css({
            left: MousePosX,
            top: MousePosY,
        });

        button.element.addClass('is-transitioning');
    }

    /**
     * Remove transition's class
     */
    function onTransitionend(event: JQueryEventObject = null): void {
        const button: JQuery = $(this).parents('.button');
        button.removeClass('is-transitioning');

        if (button.hasClass('js-button-trigger')) {
            button.addClass('is-triggered');
            setTimeout(() => {
                button.removeClass('is-triggered');
            }, 6000);
        }
    }

    function scrollToMe(event: JQueryEventObject = null): void {
        Helpers.scrollTo(event);
    }

    function onInit(): void {
        $(document).on('touchstart mousedown', '.button', onMousedown);
        $(document).on('transitionend webkitTransitionEnd oTransitionEnd', '.button__bubble', onTransitionend);
        $(document).on('touchstart mousedown', '.h-scrollto', scrollToMe);
        $(document).on('click', '.js-reorder', function(event) {
            event.preventDefault();
            Ajax.post({
                action: 're-order',
                data: $(this).data('order'),
            }).then((result) => {
                if (result && result.redirect) {
                    window.location.href = result.redirect;
                } else {
                    window.location.reload();
                }
            });
        });
    }

    $(onInit);
}
