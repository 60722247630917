// import('import/js/moment.js');
// import('import/js/pikaday.js');

namespace gotoAndPlay {

    export class Datepicker {

        public element: JQuery;
        public input: JQuery;
        public datepicker: Pikaday;
        private dateFormat: string;

        constructor(target: HTMLInputElement) {
            this.element = $(target);
            this.input = this.element.find('.js-datepicker');
            if (this.input.hasClass('dmy')) {
                this.dateFormat = 'DD/MM/YYYY';
            } else if (this.input.hasClass('mdy')) {
                this.dateFormat = 'MM/DD/YYYY';
            } else if (this.input.hasClass('dmy_dash')) {
                this.dateFormat = 'DD-MM-YYYY';
            } else if (this.input.hasClass('dmy_dot')) {
                this.dateFormat = 'DD.MM.YYYY';
            } else if (this.input.hasClass('ymd_slash')) {
                this.dateFormat = 'YYYY/MM/DD';
            } else if (this.input.hasClass('ymd_dash')) {
                this.dateFormat = 'YYYY-MM-DD';
            } else if (this.input.hasClass('ymd_dot')) {
                this.dateFormat = 'YYYY.MM.DD';
            } else {
                this.dateFormat = 'DD.MM.YYYY';
            }
            this.onInit();
        }

        onInit(): void {
            this.datepicker = new Pikaday({
                field: $(this.input)[0],
                firstDay: 1,
                format: this.dateFormat,
                minDate: new Date(), // enable to choose only upcoming dates (including current day)
            });
        }
    }

    export function initDatePickers() {
        $('.form-datepicker:visible').each(function () {
            let datepicker = new Datepicker(this);
        });
    }

    $(function () {
        initDatePickers();
    });

}
