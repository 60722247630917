// import('import/js/slick.js');

namespace gotoAndPlay {

    interface ISliderVariants {
        testimonialSlider?: string;
        trainingSlider?: string;
        productSlider?: string;
        relatedSlider?: string;
        dotsSlider?: string;
        shortcodeSlider?: string;
    }

    interface ISliderConfig {
        defaultEaseFn?: string;
        prevArrow?: string;
        nextArrow?: string;
    }

    export class Slider {
        element: JQuery;
        slick: JQuery;
        variants: ISliderVariants;
        sliderConfig: ISliderConfig;
        sliderThumbs: JQuery;
        accordionClass: JQuery;
        accTriggerClass: string;

        constructor(target: HTMLElement) {
            this.accTriggerClass = 'js-accordion-control';

            this.variants = {
                testimonialSlider: 'js-testimonial-slider',
                trainingSlider: 'js-training-slider',
                productSlider: 'js-product-slider',
                relatedSlider: 'js-related-blog',
                dotsSlider: 'js-dots-slider',
                shortcodeSlider: 'js-shortcode-slider',
            };

            this.sliderConfig = {
                defaultEaseFn: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
                prevArrow: '<button class="slider__arrow slider__arrow--left"><svg class="slider__arrow-icon icon"><use xlink:href="' + gotoAndPlay.svgPath +  '#ic_24_chevron"></use></svg></button>',
                nextArrow: '<button class="slider__arrow slider__arrow--right"><svg class="slider__arrow-icon icon"><use xlink:href="' + gotoAndPlay.svgPath +  '#ic_24_chevron"></use></svg></button>',
            };

            this.element = $(target);
            this.accordionClass = $('.js-slider-accordion');
        }

        init(): void {
            const config = this.sliderConfig;
            if (this.element.hasClass(this.variants.testimonialSlider)) {
                this.slick = this.element.slick({
                    draggable: true,
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    speed: 500,
                    mobileFirst: true,
                    cssEase: config.defaultEaseFn,
                    variableWidth: true,
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 1439,
                            settings: {
                                slidesToShow: 5,
                                arrows: true,
                                prevArrow: config.prevArrow,
                                nextArrow: config.nextArrow,
                                variableWidth: false,
                            },
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 4,
                                arrows: true,
                                prevArrow: config.prevArrow,
                                nextArrow: config.nextArrow,
                                variableWidth: false,
                            },
                        },
                        {
                            breakpoint: 1023,
                            settings: {
                                slidesToShow: 3,
                                variableWidth: false,
                            },
                        },
                        {
                            breakpoint: 520,
                            settings: {
                                slidesToShow: 2,
                            },
                        },
                    ],
                });
            } else if (this.element.hasClass(this.variants.trainingSlider)) {
                this.slick = this.element.slick({
                    draggable: true,
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    mobileFirst: true,
                    speed: 500,
                    cssEase: config.defaultEaseFn,
                    responsive: [
                        {
                            breakpoint: 1440,
                            settings: {
                                slidesToShow: 4,
                            },
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 4,
                            },
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 3,
                            },
                        },
                        {
                            breakpoint: 520,
                            settings: {
                                slidesToShow: 2,
                            },
                        },
                    ],
                });
            } else if (this.element.hasClass(this.variants.productSlider)) {
                this.slick = this.element.slick({
                    draggable: true,
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    mobileFirst: true,
                    speed: 500,
                    variableWidth: true,
                    prevArrow: config.prevArrow,
                    nextArrow: config.nextArrow,
                    cssEase: config.defaultEaseFn,
                    responsive: [
                        {
                            breakpoint: 1439,
                            settings: {
                                arrows: true,
                                slidesToShow: 3,
                                variableWidth: false,
                            },
                        },
                        {
                            breakpoint: 1024,
                            settings: {
                                arrows: true,
                                slidesToShow: 3,
                                variableWidth: false,
                            },
                        },
                        {
                            breakpoint: 520,
                            settings: {
                                slidesToShow: 2,
                                variableWidth: false,
                            },
                        },
                    ],
                });
            } else if (this.element.hasClass(this.variants.relatedSlider)) {
                this.slick = this.element.slick({
                    draggable: true,
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    mobileFirst: true,
                    speed: 500,
                    variableWidth: true,
                    prevArrow: config.prevArrow,
                    nextArrow: config.nextArrow,
                    cssEase: config.defaultEaseFn,
                    responsive: [
                        {
                            breakpoint: 1023,
                            settings: {
                                arrows: true,
                                slidesToShow: 3,
                                variableWidth: false,
                            },
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                                variableWidth: false,
                            },
                        },
                        {
                            breakpoint: 520,
                            settings: {
                                slidesToShow: 2,
                                variableWidth: false,
                            },
                        },
                    ],
                });
            } else if (this.element.hasClass(this.variants.dotsSlider)) {
                this.slick = this.element.slick({
                    dots: true,
                    draggable: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    mobileFirst: true,
                    speed: 500,
                    variableWidth: false,
                    adaptiveHeight: false,
                    fade: true,
                    prevArrow: config.prevArrow,
                    nextArrow: config.nextArrow,
                    cssEase: config.defaultEaseFn,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                arrows: true,
                            },
                        },
                    ],
                });
            } else if (this.element.hasClass(this.variants.shortcodeSlider)) {
                this.slick = this.element.slick({
                    draggable: false,
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 500,
                    arrows: true,
                    prevArrow: config.prevArrow,
                    nextArrow: config.nextArrow,
                    cssEase: config.defaultEaseFn,
                    adaptiveHeight: true,
                });

                if ( this.element.parents('.slider').hasClass('slider--vertical') ) {
                    this.slick.slick('slickSetOption', { arrows: false }, true );
                }
            } else {
                this.slick = this.element.slick({
                    draggable: false,
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 500,
                    arrows: true,
                    prevArrow: config.prevArrow,
                    nextArrow: config.nextArrow,
                    cssEase: config.defaultEaseFn,
                });

                if ( this.element.parents('.slider').hasClass('slider--vertical') ) {
                    this.slick.slick('slickSetOption', { arrows: false }, true );
                }
            }

            const slider = this.slick;
            this.accordionClass.find('.' + this.accTriggerClass).on('click', function(){
                slider.resize();
            });

            $(document).on('click', '.slider__thumbnail', onThumbnailClick);
        }

        onChangeSlide(event: JQueryEventObject): void {
            // reset active element
            $('.slider__thumbnail').removeClass('is-active');

            // add current element as active
            $(event.currentTarget).addClass('is-active');

            // Move big slide
            const selectedIndex = $(event.currentTarget).data('index');
            const slider = $(event.target).closest('.slider').find('.js-slider');
            slider.slick('slickGoTo', selectedIndex);
        }
    }

    function onThumbnailClick(event: JQueryEventObject): void {
        event.preventDefault();
        const slider: Slider = new Slider(this);
        slider.onChangeSlide(event);
    }

    function onInit(): void {
        if ( $('.js-slider').length ) {
            $('.js-slider').each(function() {
                const slider: Slider = new Slider(this);
                slider.init();
            });
        }
    }

    $(document).ready(onInit);
}
