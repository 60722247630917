namespace gotoAndPlay {

    interface ILanguagesSettings {
        langListClass?: string;
        toggleElement?: string;
        openClass?: string;
    }

    /**
     * @brief      Languages toggle class
     */
    export class Languages {

        element: JQuery;
        settings: ILanguagesSettings;
        document: JQuery;
        desk: boolean;

        constructor(target: HTMLElement) {
            this.settings = {
                langListClass: 'languages__list',
                toggleElement: 'languages__link',
                openClass: 'is-open',
            };

            this.element = $(target);
            this.document = $(document);
            this.desk = false;

            this.init();
        }

        init(): void {
            if ( $(window).width() > 1024 ) {
                this.desk = true;
            }

            $(window).resize(function(){
                if ( $(window).width() > 1024 ) {
                    this.desk = true;
                }
            });

            // console.log('initttted');

            $('.' + this.settings.toggleElement).on('click', this.toggleItems.bind(this));
            $('.language__toggle').on('touchstart', this.toggleItems.bind(this));
        }

        toggleItems(event): void {
            // console.log('openlangs');
            event.stopPropagation();
            event.preventDefault();

            if ( $(event.target).parents('.languages__item').hasClass('is-current') ) {
                let self = $(event.target).closest('.' + this.settings.langListClass);
                if ( self.length && !this.desk ) {
                    self.toggleClass(this.settings.openClass);
                }
            } else if ($(event.currentTarget).parents('.languages')) {
                let self = $(event.currentTarget).parent('.languages').find('.' + this.settings.langListClass);
                if ( self.length && !this.desk ) {
                    // console.log('teeme');
                    $(event.currentTarget).parents('.languages').toggleClass(this.settings.openClass);
                }
            }
        }
    }

    // Init on document ready
    $(function() {
        $('.js-languages').each(function() {
            const languages = new Languages(this);
        });
    });
}
