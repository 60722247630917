namespace gotoAndPlay {
    export class Popup {

        element: JQuery;
        form: JQuery;
        popupId: number;

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.form    = this.element.find('.popup__form');
            this.popupId = this.element.data('id');

            this.bindEvents();

            // Show popup on page load
            this.triggerPopup();
        }

        bindEvents() {
            this.form.on('submit', this.sendForm.bind(this));
        }

        triggerPopup() {
            let timeout = ( this.element.data('timeout') ? parseFloat(this.element.data('timeout')) * 1000 : 300 );
            let triggerLink = $('.js-popup-modaal').first();

            setTimeout(() => {
                triggerLink.modaal({
                    start_open: true,
                    overlay_opacity: 0.76,
                    overlay_close: false,
                    custom_class: triggerLink.data('custom-class'),
                    after_open: this.afterOpen.bind(this),
                    before_close: this.beforeClose.bind(this),
                });
            }, timeout);
        }

        afterOpen(elem) {
            initCheckfields();
        }

        beforeClose() {
            if ( this.popupId ) {
                Cookie.set('hide-' + this.popupId, true, 8765); // One year
            }
        }

        sendForm(event) {
            event.preventDefault();

            // Overwrite element
            this.element = $(event.target).parents('.popup');

            let subscribeXhr;
            let form = $(event.target);
            let formData: any = form.serialize();

            // Add loading class
            this.element.addClass('is-loading');
            form.find('.js-popup-newsletter').prop('disabled', true);

            if ( subscribeXhr ) {
                subscribeXhr.abort();
            }

            if ( Validation.validate(form) ) {
                subscribeXhr = Ajax.post(formData).done((result) => {

                    if (result.success) {
                        this.element.addClass('is-success');

                        if ( this.popupId ) {
                            Cookie.set('hide-' + this.popupId, true, 8765); // One year
                        }
                    } else {
                        let errorMessage = {
                            name: 'email',
                            message: result.message,
                        };

                        Validation.render(form, [errorMessage]);
                        form.find('.js-popup-newsletter').prop('disabled', false);
                    }
                }).fail((error) => {
                    console.log(error);
                }).always((result) => {
                    this.element.removeClass('is-loading');
                });
            }
        }
    }

    // Init on document ready
    $(function() {
        $('.popup').each(function() {
            const popup = new Popup(this);
        });
    });
}
