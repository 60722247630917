namespace gotoAndPlay {

    export interface ITextFieldSettings {
        focusClass?: string;
        dirtyClass?: string;
        editClass?: string;
        closeClass?: string;
    }

    export class TextField {

        public settings: ITextFieldSettings;
        public element: JQuery;
        public input: JQuery;
        public label: JQuery;

        constructor(target: HTMLInputElement, settings: ITextFieldSettings = {}) {

            this.settings = $.extend({
                dirtyClass: 'is-dirty',
                focusClass: 'is-focused',
                editClass: 'is-edit',
                closeClass: 'form-textfield__close-icon',
            }, settings) as ITextFieldSettings;

            this.element = $(target);
            this.input = this.element.find('.form-textfield__input');
            this.label = this.element.find('.form-textfield__label');
            this.input.on('focus', this.onFocus.bind(this));
            this.input.on('blur', this.onBlur.bind(this));
            this.input.on('change', this.onChange.bind(this));
            this.element.on('click', this.onEdit.bind(this));
            $('.' + this.settings.closeClass).on('click', this.onClear.bind(this));

            this.checkValue();
        }

        onEdit(): void {
            if (this.element.hasClass(this.settings.editClass)) {
                this.element.removeClass(this.settings.editClass);
                this.input.removeAttr('disabled').focus();
            }
        }

        onClear(): void {
            this.input.val('');
            this.checkValue();
        }

        onFocus(event: JQueryEventObject = null): void {
            this.element.addClass(this.settings.focusClass);
        }

        onBlur(event: JQueryEventObject = null): void {
            setTimeout(() => {
                this.element.removeClass(this.settings.focusClass);
            }, 100);
        }

        onChange(event: JQueryEventObject = null): void {
            this.checkValue();
        }

        checkValue(): void {
            if (this.input.val() === '') {
                this.element.removeClass(this.settings.dirtyClass);
            } else {
                this.element.addClass(this.settings.dirtyClass);
            }
        }
    }

    export function initCheckfields() {
        $('.form-textfield').each(function () {
            const textfield = new TextField(this);
        });
    }

    $(function () {
        initCheckfields();
    });

}
