namespace gotoAndPlay {
    export class ProductPoster {

        element: JQuery;
        cart: JQuery;
        variations: JQuery;
        cartSubmit: JQuery;
        doingAjax: boolean;

        constructor(target: HTMLElement) {
            this.element    = $(target);
            this.cart       = this.element.find('form.product-poster__form');
            this.variations = this.cart.find('.js-product-attr');
            this.cartSubmit = this.cart.find('button[type="submit"]');

            this.bindEvents();
            this.updateVariation();
        }

        bindEvents() {
            this.cart.on('submit', this.addToCart.bind(this));
            this.variations.on('change', this.updateVariation.bind(this));
        }

        addToCart(event: JQueryEventObject) {
            event.preventDefault();

            this.element.addClass('is-loading');
            $('.js-minicart .cart').addClass('is-loading');
            if (!this.doingAjax) {
                const formData          = this.cart.serializeObject();
                // object serialize dash fix
                formData['add-to-cart'] = this.cart.find('[name="add-to-cart"]').val();
                this.doingAjax          = true;
                Ajax.post(formData, window.location.href).done((result) => {
                    setTimeout(() => {
                        this.cartSubmit.removeClass('is-triggered');
                    }, 2000);
                    if (result.minicart) {
                        updateMinicart(result.minicart, result.cartCount, true);
                    }
                }).fail((error) => {
                    console.log(error);
                }).always((result) => {
                    this.doingAjax = false;
                    this.element.removeClass('is-loading');
                    $('.js-minicart .cart').removeClass('is-loading');
                });
            }

        }

        updateVariation(event?: JQueryEventObject) {
            let variation = this.variations.find('input:checked').data('variation-id');
            this.cart.find('input[name="variation_id"]').val(variation);

        }
    }

    $(function() {
        $('.product-poster').each(function() {
            const poster = new ProductPoster(this);
        });
    });

    class LHVCalc {

        form: JQuery;
        currency: string;
        types: JQuery;
        price: number;
        periodInput: JQuery;
        advanceInput: JQuery;
        calcPrice: JQuery;
        calcPay: JQuery;
        calcResult: JQuery;
        calcPerMonth: JQuery;
        calcFee: JQuery;
        perMonthLabel: any;

        constructor() {
            this.currency = '€';

            $(document).on('change', '.js-lhv-calc form', this.setup.bind(this));
        }

        setup(event: JQueryEventObject) {
            this.form          = $(event.target).closest('.js-lhv-calc form');
            this.types         = this.form.find('.js-lhv-calc-types');
            this.price         = Helpers.numeric(this.form.find('[name="percentage"]').data('price'));
            this.periodInput   = this.form.find('[name="period"]');
            this.advanceInput  = this.form.find('[name="percentage"]');
            this.calcPrice     = this.form.find('.js-lhv-calc-price');
            this.calcPay       = this.form.find('.js-lhv-calc-pay');
            this.calcResult    = this.form.find('.js-result');
            this.calcPerMonth  = this.form.find('.js-lhv-calc-pay-per');
            this.perMonthLabel = this.form.find('.js-lhv-calc-per-month').html();
            this.calcFee       = this.form.find('.js-lhv-calc-fee');

            this.calcPrice.text(this.price + ' ' + this.currency);
            this.filter();
        }

        filter() {
            let currentType = this.types.find('[name="lease-type"]:checked');

            // fee field
            let fee         = currentType.length && typeof currentType.data('fee') !== 'undefined' ? Helpers.numeric(currentType.data('fee')) : 0;
            if (fee) {
                this.calcFee.html(fee + ' ' + this.currency).closest('.filter__data-item').show();
            } else {
                this.calcFee.closest('.filter__data-item').hide();
            }

            if (currentType.length) {
                switch (currentType.val()) {
                    case 'lhv':
                        this.filterLhv();
                        break;
                    case 'esto':
                        this.filterEsto(currentType);
                        break;
                }
            } else {
                this.filterLhv();
            }
        }

        filterLhv() {
            let period  = Helpers.numeric(this.periodInput.val());
            let advance = Helpers.numeric(this.advanceInput.val());

            let loan                = new LHV.hirePurchase();
            loan.price              = this.price;
            loan.advance            = this.price * advance / 100;
            loan.period             = period;
            loan.interest           = 9.9;
            loan.priceBasedInterest = true;
            this.calcPay.text((this.price * advance / 100) + ' ' + this.currency + ' (' + advance + '%)');
            let monthlyPayment = loan.getMonthlyPayment();
            let append         = '<span class="js-lhv-calc-per-month">' + this.perMonthLabel + '</span>';
            if (monthlyPayment.payment) {
                this.calcResult.html(monthlyPayment.payment + ' ' + this.currency + append);
                this.calcPerMonth.text(period + 'x' + monthlyPayment.payment + ' ' + this.currency + ' = ' + Helpers.numberFormat(monthlyPayment.payment * period) + ' ' + this.currency);
            } else {
                this.calcResult.html('-' + append);
                this.calcPerMonth.text('-');
            }
        }

        filterEsto(typeInput: JQuery) {
            let interestPrc = typeof typeInput.data('interest') !== 'undefined' ? Helpers.numeric(typeInput.data('interest')) : 0;
            let period      = Helpers.numeric(this.periodInput.val());
            let advancePrc  = Helpers.numeric(this.advanceInput.val());
            let append      = '<span class="js-lhv-calc-per-month">' + this.perMonthLabel + '</span>';

            // advance payment
            let advance = this.price * advancePrc / 100;
            this.calcPay.text(Helpers.numberFormat(advance) + ' ' + this.currency + ' (' + advancePrc + '%)');

            // monthly payment
            let finalSum = (this.price - advance) + (((this.price - advance) * interestPrc / 100) / 12 ) * period;
            let finalMonth = Math.round(finalSum / period * 100) / 100;

            this.calcResult.html(Helpers.numberFormat(finalMonth) + ' ' + this.currency + append);
            this.calcPerMonth.text(period + 'x' + Helpers.numberFormat(finalMonth) + ' ' + this.currency + ' = ' + Helpers.numberFormat(finalMonth * period) + ' ' + this.currency);

            // let monthlyBase    = (this.price - advance) / period;
            // let monthlyPayment = Math.round((monthlyBase * advancePrc / 100 + monthlyBase) * 100) / 100;
            //
            // this.calcResult.html(Helpers.numberFormat(monthlyPayment) + ' ' + this.currency + append);
            //
            // this.calcPerMonth.text(period + 'x' + Helpers.numberFormat(monthlyPayment) + ' ' + this.currency + ' = ' + Helpers.numberFormat(monthlyPayment * period) + ' ' + this.currency);
        }
    }

    export let lhvCalc = new LHVCalc();

}
