namespace gotoAndPlay {
    export class RatingForm {

        element: JQuery;
        stars: JQuery;
        total: JQuery;
        selected: JQuery;

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.stars = this.element.find('.stars a');
            this.total = this.element.find('.js-rating-total');
            this.selected = this.element.find('.js-rating-selected');

            this.total.html(this.stars.count);
            this.bindEvents();
        }

        bindEvents() {
            this.stars.on('click', this.updateRating.bind(this));
        }

        updateRating(event?: JQueryEventObject) {
            let rating = $(event.target).closest('a');
            this.selected.html(rating.text());
        }
    }

    export function initRating() {
        $('.js-rating-form').each(function(){
            const rating = new RatingForm(this);
        });
    }
}
