namespace gotoAndPlay {
    export class Filter {

        element: JQuery;
        form: JQuery;
        resultCount: JQuery;
        doingAjax: boolean;
        debounce: number;
        contentClass: string;
        submitBtn: JQuery;
        resizeTimer: number;

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.form    = this.element.find('form');
            this.resizeTimer = 0;
            if (this.form.attr('action')) {
                this.resultCount  = this.element.find('.js-result');
                this.contentClass = '.js-product-grid';
                this.submitBtn    = this.element.find('filter__submit-button');

                this.bindEvents();
            }
        }

        bindEvents() {
            this.form.on('change', this.updateProducts.bind(this));
            this.submitBtn.on('click', this.submitFilter.bind(this));
            $(document).on('click', '.js-open-filter', this.toggleMobileFilter);
            $(document).on('click', '.js-close-filter', this.toggleMobileFilter);
            $(document).on('click', '.pagination.is-filter', this.paginateFilter.bind(this));
            $(window).on('resize', this.resize);

            // Reinit filter with browser back event
            const that = this;
            if (document.addEventListener) {
                window.addEventListener('pageshow', function (event) {
                    if (event.persisted || window.performance && window.performance.navigation.type == 2) {
                        that.filterProducts();
                    }
                }, false);
            }
        }

        toggleMobileFilter() {
            if ($('body').hasClass('filter-open')) {
                Helpers.enableScroll();
                $('body').removeClass('filter-open');
            } else {
                Helpers.disableScroll();
                $('body').addClass('filter-open');
            }
        }

        resize(event: JQueryEventObject) {
            clearTimeout(this.resizeTimer);
            this.resizeTimer = window.setTimeout(() => {
                if ( window.innerWidth > 768 ) {
                    // Hide filter modal
                    Helpers.enableScroll();
                    $('body').removeClass('filter-open');
                }
            }, 250);
        }

        updateProducts(event: JQueryEventObject) {
            clearTimeout(this.debounce);
            if ($(event.target).is('form')) {
                this.form = $(event.target);
            }
            this.debounce = setTimeout(this.filterProducts.bind(this), 600);
        }

        filterProducts() {
            const formData  = this.form.serializeObject();
            this.doingAjax  = true;
            let content     = $(this.contentClass);
            let pagination = content.parent().find('.pagination');
            let resultCount = $('.filter .js-result');
            content.addClass('is-loading');
            content.find('.js-no-products').addClass('h-hidden');
            Ajax.post(formData).done((result) => {
                if (result.success) {
                    resultCount.html(result.count);
                    if (content.length) {
                        content.replaceWith(result.content);
                        content = $(this.contentClass);
                        if (result.count == 0) {
                            $(this.contentClass).find('.js-no-products').removeClass('h-hidden');
                        }

                        if (pagination.length) {
                            pagination.replaceWith(result.pagination);
                        } else {
                            content.after(result.pagination);
                        }
                    }
                }
            }).fail((error) => {
                console.log(error);
            }).always((result) => {
                this.doingAjax = false;
                content.removeClass('is-loading');
            });
        }

        paginateFilter(event: JQueryEventObject) {
            event.preventDefault();
            event.stopPropagation();

            let elem = $(event.target).closest('.pagination__link');
            if (elem.attr('href')) {
                this.form.attr('action', elem.attr('href')).submit();
            }
        }

        submitFilter(event: JQueryEventObject) {
            let elem = $(event.target);
            let form = elem.closest('.filter');
            if (form.length) {
                form.submit();
            }
        }
    }

    // Init on document ready
    $(function() {
        $(document).on('mouseover', '.js-minicart, .js-login-box', function(event) {
            $(this).removeClass('is-active');
        });
        $(document).on('click', 'button.js-add-to-card', function(event) {
            event.preventDefault();
            if ($(this).data('id')) {
                Ajax.post({
                    action: 'add-to-cart',
                    data: $(this).data('id'),
                }).then((result) => {
                    if ($('body').hasClass('woocommerce-cart')) {
                        if ($(this).hasClass('js-close-filter')) {
                            $('[href="#modal-free-shipping"]').modaal('close');
                        }
                        $(document).trigger('updated_cart_totals');
                    } else {
                        if ($(this).hasClass('js-close-filter')) {
                            $('[href="#modal-postpay"]').modaal('close');
                        }

                        if (result.minicart) {
                            updateMinicart(result.minicart, result.cartCount, true);
                        }
                    }
                });
            }
        });
        $(document).on('click', '.js-product-grid a.product', function(event) {
            if ($(event.target).hasClass('js-add-to-card') || $(event.target).parents('.js-add-to-card').length) {
                event.preventDefault();
                Ajax.post({
                    action: 'add-to-cart',
                    data: $(this).data('id'),
                }).then((result) => {
                    if (result.minicart) {
                        updateMinicart(result.minicart, result.cartCount, true);
                    }
                });
            }
        });
        $('.filter').each(function() {
            const filter = new Filter(this);
        });
    });
}
