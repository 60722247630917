namespace gotoAndPlay {

    export let packages: any;

    if (packages) {
        packages = JSON.parse(packages);
        if (packages && packages.length) {
            $(document).on('change', '.js-meetings-package-select select', function (event) {
                let selected = $(this).val();
                if (selected) {
                    for (let i = 0; i < packages.length; i++) {
                        let package = packages[i];
                        if (package.title == selected) {
                            $('.js-meetings-package-title').text(package.title);
                            $('.js-meetings-package-price').text(package.price);
                            $('.js-meetings-package-length').text(package.time);
                            $('.js-meetings-package-location').text(package.location);
                        }
                    }
                }
            });
            $(document).on('click', '.js-meetings-package-button', function (event) {
                $('.js-meetings-package-select select').val($(this).data('package-id')).trigger('change');
            });
            $(document).on('click', '.js-meetings-button', function (event) {
                $('.js-meetings-package-select select').val(packages[0].title).trigger('change');
            });
        }
    }
}
