(function ($) {
    $.fn.serializeObject = function () {

        const self = this;
        let json = {};
        const pushCounters = {};
        const patterns = {
            validate: /^[a-zA-Z][a-zA-Z0-9_-]*(?:\[(?:\d*|[a-zA-Z0-9_-]+)\])*$/,
            key: /[a-zA-Z0-9_-]+|(?=\[\])/g,
            push: /^$/,
            fixed: /^\d+$/,
            named: /^[a-zA-Z0-9_-]+$/,
        };

        this.build = function (base, key, value) {
            base[key] = value;
            return base;
        };

        this.push_counter = function (key) {
            if (pushCounters[key] === undefined) {
                pushCounters[key] = 0;
            }
            return pushCounters[key]++;
        };

        $.each($(this).serializeArray(), function () {

            // skip invalid keys
            if (!patterns.validate.test(this.name)) {
                return;
            }

            const keys = this.name.match(patterns.key);
            let k = keys.pop();
            let merge = this.value;
            let reverseKey = this.name;

            while (k !== undefined) {
                // adjust reverse_key
                reverseKey = reverseKey.replace(new RegExp('\\[' + k + '\\]$'), '');

                // push
                if (k.match(patterns.push)) {
                    merge = self.build([], self.push_counter(reverseKey), merge);
                } else if (k.match(patterns.fixed)) {
                    // fixed
                    merge = self.build([], k, merge);
                } else if (k.match(patterns.named)) {
                    // named
                    merge = self.build({}, k, merge);
                }

                k = keys.pop();
            }

            json = $.extend(true, json, merge);
        });

        return json;
    };
})(jQuery);
