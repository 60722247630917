// import('import/js/tooltipster.bundle.js');

namespace gotoAndPlay {

    export class Tooltip {

        element: JQuery;
        theme: string;
        tipDistance: number;
        contentClass: string;

        constructor(target: HTMLElement) {

            this.element = $(target);
            this.tipDistance = 10;
            this.contentClass = '.tooltip__content';

            this.init();
        }

        init(): void {
            const self = this;
            this.element.tooltipster({
                distance: this.tipDistance,
                contentAsHTML: true,
                contentCloning: true,
                arrow: false,
                maxWidth: 330,
                trigger: 'hover',
                triggerOpen: {
                    click: true,
                    touchstart: true,
                },
                triggerClose: {
                    click: true,
                    scroll: true,
                },
                functionInit: function(instance, helper: any) {
                    const content = $(helper.origin).find(self.contentClass).detach();
                    instance.content(content);
                },
                functionPosition: function(instance, helper, position) {
                    let elementPosition = $(self.element)[0].getBoundingClientRect();

                    let tooltipWidth = $(helper.tooltipClone).find('.tooltip__content').outerWidth();
                    let tooltipHeight = $(helper.tooltipClone).find('.tooltip__content').outerHeight();

                    position.coord = {
                        top: elementPosition.top - tooltipWidth, // Because tooltipWidth = tooltip real height
                        left: elementPosition.left - ( tooltipHeight / 2 ) + ( self.element.outerWidth() / 2 ), // Because tooltipHeight = tooltip real width
                    };

                    return position;
                },
            });
        }
    }

    $(function(){
        $('.tooltip').each(function() {
            $(this).data('gtapTooltip', new Tooltip(this));
        });
    });
}
