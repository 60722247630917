namespace gotoAndPlay {

    export class BragBar {

        element: JQuery;
        bar: JQuery;

        resizeTimeout;

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.bar = $(target).find('.brag-bar');
            this.init();
        }

        init(): void {
            let object = this;
            object.checkWidth();
            $(window).on('resize', function () {
                clearTimeout(object.resizeTimeout);
                object.resizeTimeout = setTimeout(function () {
                    object.checkWidth();
                }, 300);
            });
        }

        startScroll() {
            this.bar.addClass('brag-bar--fixed');

            $(this.bar).find('.brag-bar__item').each(function () {
                let clone = $(this).clone(true);
                $(this).before(clone);
                $(this).remove();
            });
        }

        stopScroll() {
            this.bar.removeClass('brag-bar--fixed');
        }

        checkWidth() {
            let width = 0;
            this.bar.find('.brag-bar__item').each(function() {
                width += $(this).find('div').width() + $(this).find('p').width() + 20;
            });

            if ($(window).width() < width) {
                this.startScroll();
            } else {
                this.stopScroll();
            }
        }
    }

    function onInit(): void {
        $('.brag-container').each(function() {
            const bBar: BragBar = new BragBar(this);
        });
    }

    $(onInit);
}
