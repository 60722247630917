namespace gotoAndPlay {

    class Autosuggest {

        private xhr: JQueryXHR;
        private timeout: any;
        private currentValue: string;

        constructor() {
            $(document).on('change keyup blur', '.js-search-autosuggest input', this.suggest.bind(this));
        }

        suggest(event) {
            let parent = $(event.currentTarget).parents('.js-search-autosuggest');
            let input = parent.find('form input');
            let list = parent.find('.js-search-autosuggest-results');
            if (input.val() != this.currentValue) {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                if (this.xhr) {
                    this.xhr.abort();
                }
            }
            if (input.val() != this.currentValue && input.val().length > 0) {
                parent.addClass('is-dirty');
                list.addClass('is-loading');
                this.timeout = setTimeout(() => {
                    this.currentValue = input.val();
                    this.xhr = Ajax.post({
                        action: 'autosuggest',
                        data: this.currentValue,
                    });
                    this.xhr.then((result) => {
                        if (result && result.success) {
                            parent.find('.js-search-autosuggest-results').addClass('is-open').html(result.results);
                        } else {
                            parent.find('.js-search-autosuggest-results').removeClass('is-open').html('');
                        }
                        list.removeClass('is-loading');
                    });
                }, 250);
            } else if (!input.val()) {
                this.currentValue = null;
                list.removeClass('is-loading');
                parent.removeClass('is-dirty');
                parent.find('.js-search-autosuggest-results').removeClass('is-open').html('');
            }
        }
    }

    $(document).on('click', '.js-section-show-all', (event) => {
        event.preventDefault();
        let target = $(event.currentTarget);
        target.hide();
        target.parents('.js-section').find('.js-article').show();
    });
    $(document).on('change', '.js-search-filter input', (event) => {
        if ($('.js-search-filter input:checked').length > 0) {
            $('.js-section-filter').hide();
            $('.js-search-filter input:checked').each(function () {
                $('.js-section-' + $(this).attr('name')).show();
            });
        } else {
            $('.js-section-filter').show();
        }
    });
    $(document).on('click', '.js-search-sort', (event) => {
        event.preventDefault();
        $('.js-section-sort').each((function () {
            let key;
            let direction = 1;
            switch ($(event.currentTarget).attr('href')) {
                case '#down':
                    key = 'price';
                    direction = -1;
                    break;
                case '#up':
                    key = 'price';
                    break;
                case '#new':
                    key = 'stamp';
                    direction = -1;
                    break;
                default:
                    key = 'stamp';
                    break;
            }
            $(this).find('.js-search-sortable').sort(function (a, b) {
                if (direction > 0) {
                    return Helpers.numeric($(a).data(key)) - Helpers.numeric($(b).data(key));
                } else {
                    return Helpers.numeric($(b).data(key)) - Helpers.numeric($(a).data(key));
                }
            }).appendTo($(this));
            if ($(this).parents('.js-section').find('.js-section-show-all:visible').length) {
                $(this).find('.js-search-sortable').hide();
                $(this).find('.js-search-sortable:lt(12)').show();
            }
        }));
    });

    export let autosuggest = new Autosuggest();

    $(function () {
        $('.js-search-filter input:checked').trigger('change');
    });
}
