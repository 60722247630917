namespace gotoAndPlay {

    export interface INumberFieldSettings {
        dirtyClass?: string;
    }

    export class NumberField {

        public settings: INumberFieldSettings;
        public element: JQuery;
        public input: JQuery;
        public label: JQuery;
        public increment: JQuery;
        public decrement: JQuery;
        public value: JQuery;
        public min: number;
        public max: number;

        constructor(target: HTMLInputElement, settings: INumberFieldSettings = {}) {

            this.settings = $.extend({
                dirtyClass: 'is-dirty',
                focusClass: 'is-focused',
            }, settings) as INumberFieldSettings;

            this.element = $(target);
            this.input = this.element.find('.form-textfield__input');
            this.label = this.element.find('.form-textfield__label');
            this.increment = this.element.find('.form-textfield__increment');
            this.decrement = this.element.find('.form-textfield__decrement');
            this.value = this.input.val();
            this.min = Helpers.numeric(this.input.data('min'));
            this.max = Helpers.numeric(this.input.data('max'));

            this.input.on('change', this.onChange.bind(this));
            this.increment.on('click', this.addNum.bind(this));
            this.decrement.on('click', this.removeNum.bind(this));

            this.increment.on('mouseover', this.showTooltip.bind(this));
            this.increment.on('mouseout', this.closeTooltip.bind(this));
            this.decrement.on('mouseover', this.showTooltip.bind(this));
            this.decrement.on('mouseout', this.closeTooltip.bind(this));

            this.element.addClass('is-init');

            this.checkValue();
        }

        showTooltip(): void {
            $('.qtyTooltip', this.element).tooltipster('show');
        }

        closeTooltip(): void {
            $('.qtyTooltip', this.element).tooltipster('hide');
        }

        addNum(): void {
            if (this.max) {
                if (this.input.val() < this.max) {
                    this.input.val(parseInt(this.input.val()) + 1);
                }
            } else {
                this.input.val(parseInt(this.input.val()) + 1);
            }

            this.input.trigger('change');
        }

        removeNum(): void {
            if (this.input.val() >= this.min) {
                this.input.val(parseInt(this.input.val()) - 1);
            }

            this.input.trigger('change');
        }

        onChange(event: JQueryEventObject = null): void {
            this.checkValue();
        }

        checkValue(): void {
            if (this.input.val() === '') {
                this.element.removeClass(this.settings.dirtyClass);
            } else {
                this.element.addClass(this.settings.dirtyClass);
            }

            if (this.max && this.input.val() > this.max) {
                this.input.val(this.max);
            }
        }
    }

    export function initNumberInputs(container?: JQuery) {
        let inputs = $('.form-number');
        if (typeof container !== 'undefined') {
            inputs = $(container).find('.form-number');
        }
        inputs.each(function () {
            if (!$(this).hasClass('is-init')) {
                const numberfield = new NumberField(this);
            }
        });
    }

    $(function () {
        initNumberInputs();
    });

}
