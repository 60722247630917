// import('import/js/jquery.maskMoney.js');

namespace gotoAndPlay {

    export interface IRadioFieldSettings {
        focusClass?: string;
        dirtyClass?: string;
        selectedClass?: string;
        selectTrigger?: string;
    }

    export class RadioField {

        public settings: IRadioFieldSettings;
        public element: JQuery;
        public input: JQuery;
        public textInput: JQuery;
        public selectElement: JQuery;

        constructor(target: HTMLInputElement, settings: IRadioFieldSettings = {}) {

            this.settings = $.extend({
                focusClass: 'is-focused',
                dirtyClass: 'is-dirty',
                selectedClass: 'is-selected',
                selectTrigger: 'form-radio__trigger',
            }, settings) as IRadioFieldSettings;

            this.element = $(target);
            this.input = this.element.find('.form-radio__input');
            this.textInput = this.element.find('.form-radio__value');
            this.selectElement = this.element.find('.form-radio__trigger');

            this.textInput.on('focus', this.onFocus.bind(this));
            this.textInput.on('blur', this.onBlur.bind(this));
            this.textInput.on('change', this.onChange.bind(this));
            // this.input.on('change', this.onSelect.bind(this));
            // $('.' + this.settings.selectTrigger).on('click', this.onClear.bind(this));
            this.element.on('click', this.onClear.bind(this));

            this.checkValue();
        }

        onFocus(event: JQueryEventObject = null): void {
            this.element.addClass(this.settings.focusClass);

            this.textInput.maskMoney({
                thousands: '',
                decimal: '.',
                precision: 0,
                reverse: true,
                allowZero: false,
                suffix: ' €',
            });
        }

        onBlur(event: JQueryEventObject = null): void {
            setTimeout(() => {
                this.element.removeClass(this.settings.focusClass);
            }, 100);
        }

        onChange(event: JQueryEventObject = null): void {
            this.checkValue();
        }

        onSelect(event: JQueryEventObject = null): void {
            if ( $(event.target).is(':checked') ) {
                this.textInput.attr('disabled', 'disabled');
                // this.element.addClass(this.settings.selectedClass);
            }
        }

        onClear(event: JQueryEventObject = null): void {
            if ( $(event.target).is(':checked') ) {
                // $(this.input).prop('checked', false);
            }

            /*
            if ( this.element.hasClass('is-selected') ) {
                this.textInput.val('').removeAttr('disabled').focus();
                this.element.removeClass('is-selected is-dirty is-focused');
            }
            */
        }

        checkValue(): void {
            if (this.textInput.val() === '') {
                this.element.removeClass(this.settings.dirtyClass);
            } else {
                this.element.addClass(this.settings.dirtyClass);
            }
        }
    }

    $(function () {
        $('.form-radio').each(function() {
            const radio = new RadioField(this);
        });
    });
}
