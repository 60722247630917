namespace gotoAndPlay {

    export class Cookie {

        static set(cname, cvalue, exhours = 24 * 30): void {
            const d = new Date();
            d.setTime(d.getTime() + ((exhours ? exhours : 1) * 60 * 60 * 1000));
            const expires = 'expires=' + d.toUTCString();
            document.cookie = cname + '=' + cvalue + '; ' + expires;
        }

        static get(cname): any {
            const name = cname + '=';
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
        }
    }

}
