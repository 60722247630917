namespace gotoAndPlay {
    export class ProfileBox {

        element: JQuery;
        showBoxLink: JQuery;
        profileBox: JQuery;
        doingAjax: boolean;
        formList: any;
        preInit: boolean;

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.showBoxLink = this.element.find('.js-profile-box-show');
            this.profileBox = this.element.find('.js-profile-box');
            this.formList = {};

            this.init();
            this.bindEvents();
        }

        init() {
            // set profileboxes
            if (this.profileBox.length) {
                this.profileBox.each((i, el) => {
                    const elem = $(el);
                    const profileType = elem.data('type');
                    if (profileType && elem.find('form')) {
                        this.formList[profileType] = elem.find('form');
                    }
                });
            }
        }

        bindEvents() {
            this.showBoxLink.on('click', this.showBox.bind(this));
            if (this.formList.register) {
                this.formList.register.on('submit', this.submitRegisterUser.bind(this));
            }
            if (this.formList.request) {
                this.formList.request.on('submit', this.submitResetRequest.bind(this));
            }
            if (typeof this.formList.reset !== 'undefined') {
                this.formList.reset.on('submit', this.submitPasswordReset.bind(this));
            }

            // skip autofill triggered input events
            setTimeout(() => {
                this.element.on('focus', 'input', this.forceDisplay.bind(this));
                this.element.on('blur', 'input', this.removeDisplay.bind(this));
            }, 200);
        }

        showBox(event: JQueryEventObject) {
            event.preventDefault();

            const elem = $(event.target).closest('.js-profile-box-show');
            this.showProfileBox(elem.attr('href'));
            if (elem.hasClass('profile-box__head-link')) {
                this.element.find('.is-current').removeClass('is-current');
                elem.addClass('is-current');
            }
        }

        submitRegisterUser(event: JQueryEventObject) {
            event.preventDefault();

            if (this.formList.register.find('input[name="user_login"]').val() && this.formList.register.find('input[name="password"]').val()) {
                grecaptcha.ready(() => {
                    grecaptcha.execute(recaptchaKey, {
                        action: 'homepage',
                    }).then((token: string) => {
                        this.formList.register.find('.recaptcha-token').val(token);
                        const formData = this.formList.register.serializeObject();
                        this.doingAjax = true;
                        this.forceDisplay();
                        Ajax.post(formData).done((result) => {
                            if (result.success) {
                                if (result.redirect) {
                                    window.location.replace(result.redirect);
                                } else {
                                    location.reload();
                                }
                            } else {
                                this.formList.register.find('.js-profile-error').html(result.error);
                            }
                        }).fail((error) => {
                            console.log(error);
                        }).always((result) => {
                            this.doingAjax = false;
                        });
                    });
                });
            }
        }

        submitResetRequest(event: JQueryEventObject) {
            event.preventDefault();

            const formData = this.formList.request.serializeObject();
            this.doingAjax = true;
            this.forceDisplay();
            Ajax.post(formData).done((result) => {
                if (result.success) {
                    this.showProfileBox('#profile-requested');
                } else if (result.error) {
                    this.formList.request.find('.js-profile-error').html(result.error);
                }
            }).fail((error) => {
                console.log(error);
            }).always((result) => {
                this.doingAjax = false;
            });
        }

        submitPasswordReset(event: JQueryEventObject) {
            event.preventDefault();

            const formData = this.formList.reset.serializeObject();
            this.doingAjax = true;
            this.forceDisplay();
            Ajax.post(formData).done((result) => {
                if (result.success) {
                    this.showProfileBox('#profile-relogin');
                } else if (result.error) {
                    this.formList.reset.find('.js-profile-error').html(result.error);
                }
            }).fail((error) => {
                console.log(error);
            }).always((result) => {
                this.doingAjax = false;
            });
        }

        showProfileBox(boxId: string) {
            this.profileBox.addClass('h-hidden').filter(boxId).removeClass('h-hidden');
        }

        forceDisplay(event?: JQueryEventObject) {
            this.element.parent().addClass('is-fixed');
        }

        removeDisplay(event?: JQueryEventObject) {
            this.element.parent().removeClass('is-fixed');
        }
    }

    // Init on document ready
    $(function () {
        $('.profile-box form').bindFirst('submit', function (event) {
            if (!Validation.validate($(this))) {
                event.preventDefault();
                event.stopImmediatePropagation();
            }
        });
        $('.profile-box').each(function () {
            const profileBox = new ProfileBox(this);
        });
    });
}
