// import('import/js/dropdown.js');

namespace gotoAndPlay {

        export class DropDown {

            element: JQuery;
            trigger: JQuery;

            constructor(target: HTMLElement) {
                this.element = $(target);
                this.trigger = this.element.find('.js-dropdown-toggle');

                this.init();
            }

            init(): void {
                $(document).on('click', this.setOpen.bind(this));
            }

            setOpen(event): void {
                const ddMenu = $(event.target).closest(this.trigger);
                if (ddMenu.length) {
                    this.element.addClass('is-open');
                } else {
                    this.element.removeClass('is-open');
                }
            }

            closeDd(): void {
                this.element.removeClass('is-open');
            }

        }

        function onInit(): void {
            $('.dropdown').each(function () {
                const dropdown = new DropDown(this);
            });
        }

        $(onInit);
    }
