namespace gotoAndPlay {

    class ManualsList {

        public constructor() {
            $(document).on('submit', '.js-manuals-search form', (event) => {
                event.preventDefault();
                this.search();
            });
            $(document).on('change keyup', '.js-manuals-search input', this.search.bind(this));
        }

        public search() {
            let input = $('.js-manuals-search input');
            let searchVal = input.val();
            $('.js-manuals-search-group').each(function () {
                let count = 0;
                $(this).find('.js-manuals-search-item').each(function () {
                    if ($(this).text().search(new RegExp(searchVal, 'i')) < 0) {
                        $(this).hide();
                    } else {
                        count++;
                        $(this).show();
                    }
                });
                $(this).find('.js-accordion-title-count').text(count);
                if (!count && $(this).text().search(new RegExp(searchVal, 'i')) < 0) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            });
        }

    }

    export let manualsList = new ManualsList();

}
