namespace gotoAndPlay {

        export class SignupBar {
            visibleClass?: string;
            closeClass?: string;
            closeButton: JQuery;
            element: JQuery;

            constructor(target: HTMLElement) {
                this.visibleClass = 'is-visible';
                this.closeClass = 'blogi-signup-bar__close';
                this.element = $(target);
                this.closeButton = $('.' + this.closeClass);
            }

            init(): void {
                this.showBar();
                this.closeButton.on('click', this.hideBar.bind(this));
            }

            showBar(): void {
                let self = this;
                let hiddenCookie = Cookie.get('signupBar');

                if (hiddenCookie !== 'hide') {
                    setTimeout(function () {
                        self.element.addClass(self.visibleClass);
                    }, 6000);
                }
            }

            hideBar(event): void {
                event.preventDefault();
                this.element.removeClass(this.visibleClass);
                Cookie.set('signupBar', 'hide', 24);
            }
        }

        function onInit(): void {
            $('.blogi-signup-bar').each(function() {
                const signupBar: SignupBar = new SignupBar(this);
                signupBar.init();
            });
        }

        $(onInit);
    }
