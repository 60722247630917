namespace gotoAndPlay {

    export class Validation {

        private static INVALID_CLASS: string = 'is-invalid';
        private static VALID_CLASS: string = 'is-valid';

        public static isEmpty(value: string): boolean {
            return value && value.trim() != '' ? false : true;
        }

        public static isValidPassword(value: string): boolean {
            return value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/) ? true : false;
        }

        public static isValidEmail(value: string): boolean {
            return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
        }

        public static isValidPhone(value: string): boolean {
            return /^-?\d+\.?\d*$/.test(value);
        }

        public static validate(form: JQuery): boolean {
            let valid: boolean = true;
            form.find('[data-validate]:visible').each(function (index, element) {
                const target: JQuery = jQuery(element);
                const value: string = target.val();
                const type: string = target.attr('type');
                let isValid: boolean = true;
                switch (type) {
                    case 'email':
                        if (!Validation.isValidEmail(value)) {
                            if (valid) {
                                target.focus();
                            }
                            valid = false;
                            isValid = false;
                        }
                        break;
                    case 'tel':
                        if (!Validation.isValidPhone(value)) {
                            if (valid) {
                                target.focus();
                            }
                            valid = false;
                            isValid = false;
                        }
                        break;
                    case 'checkbox':
                        if (!target.is(':checked')) {
                            if (valid) {
                                target.focus();
                            }
                            valid = false;
                            isValid = false;
                        }
                        break;
                    case 'text':
                    default:
                        if (Validation.isEmpty(value)) {
                            if (valid) {
                                target.focus();
                            }
                            valid = false;
                            isValid = false;
                        }
                        break;
                }
                Validation.clear(target.parent().parent().parent());
                if (!isValid) {
                    let message: string = null;
                    if (target.attr('name') && strings.errors) {
                        const errors = strings.errors[target.attr('name')];
                        if (errors) {
                            if (!value && errors.empty) {
                                message = errors.empty;
                            } else {
                                message = errors.invalid;
                            }
                        }
                    }
                    Validation.addMessage(target.parent().parent(), message);
                }
            });
            return valid;
        }

        public static clear(form: JQuery) {
            form.find('.js-form-message').remove();
            form.find('.' + Validation.INVALID_CLASS).removeClass(Validation.INVALID_CLASS);
            form.find('.' + Validation.VALID_CLASS).removeClass(Validation.VALID_CLASS);
        }

        public static render(form: JQuery, messages: any[]) {
            Validation.clear(form);
            if (messages.length) {
                for (let i = 0; i < messages.length; i++) {
                    const message: {
                        message: string;
                        name?: string;
                        valid?: boolean;
                        onlyMessage?: boolean
                    } = messages[i];
                    let target;
                    if (message.name) {
                        target = form.find('[name="' + message.name + '"]').parent().parent();
                    } else {
                        target = form.find('[type="submit"], [data-action="submit"]').last().parent();
                    }
                    Validation.addMessage(target, message.message, message.valid, message.onlyMessage);
                }
            }
        }

        private static addMessage(target: JQuery, message: string = null, valid: boolean = false, onlyMessage: boolean = false) {
            if (!onlyMessage) {
                target.addClass(valid ? Validation.VALID_CLASS : Validation.INVALID_CLASS);
            }
            if (message) {
                const element = $('<div class="form-textfield__message is-visible js-form-message"><div class="form-textfield__message-text">' + message + '</div></div>');
                if (valid) {
                    element.addClass(Validation.VALID_CLASS);
                } else {
                    element.addClass(Validation.INVALID_CLASS);
                }
                target.append(element);
            }
        }
    }

}
