namespace gotoAndPlay {
    export class Notification {

        element: JQuery;
        content: JQuery;
        closeBtn: JQuery;

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.content = this.element.find('.js-note-content');
            this.closeBtn = this.element.find('.js-note-close');

            this.init();
            this.bindEvents();
        }

        init() {
            this.element.removeClass('isOpen');
            this.updateNote(true);
        }

        bindEvents() {
            this.closeBtn.on('click', this.closeNote.bind(this));
        }

        openNote(forceOpen = false) {
            if (!forceOpen && Cookie.get('hide-free-shipping-note')) {
                return;
            }

            if (this.content.html().trim().length) {
                this.element.addClass('isOpen');
            }
        }

        closeNote(event?: JQueryEventObject) {
            this.element.removeClass('isOpen');
            Cookie.set('hide-free-shipping-note', 'hide', 1); // Hide notification for hour
        }

        updateNote(onLoad = true) {
            Ajax.post({
                action: 'update-notification',
                data: {type: 'freeShipping'},
            }).then((result) => {
                if (result.notification) {
                    let newNote = result.notification;
                    // replace note, if new not is actually new
                    if (newNote.trim() != this.content.html().trim()) {
                        this.closeNote();
                        // 800ms delay for note transition time
                        setTimeout(() => {
                            this.content.html(newNote);

                            if (onLoad) {
                                this.openNote(false);
                            } else {
                                this.openNote(true);
                            }
                        }, 600);
                    }
                }
            });

        }
    }

    let note: Notification;
    $(function () {
        $('.notification').each(function(){
            note = new Notification(this);
        });
    });

    $(document).on('gtap.updatedCart', (event) => {
        if (note) {
            note.updateNote(false);
        }
    });
}
