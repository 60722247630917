// import('import/js/nouislider.js');

namespace gotoAndPlay {

    export interface IRangeSliderSettings {
        focusClass?: string;
        dirtyClass?: string;
        editClass?: string;
    }

    export class RangeSlider {

        element: JQuery;
        input: JQuery;
        min: number;
        max: number;
        step: number;
        postfix: string;
        controls: string;
        slider: any;
        html: any;

        constructor(target: HTMLInputElement, settings: IRangeSliderSettings = {}) {

            this.element = $(target);
            this.html = this.element.parent().html();
            this.input = this.element.find('input');
            this.min = this.element.data('min');
            this.max = this.element.data('max');
            this.step = this.element.data('step');
            this.postfix = this.element.data('postfix');
            this.controls = this.element.data('controls');

            const self = this;
            const options: any = {
                start: [5],
                step: this.step,
                connect: [true, false],
                tooltips: [true],
                range: {
                    min: this.min,
                    max: this.max,
                },
                format: {
                    to: function (value) {
                        if (self.postfix) {
                            return Math.floor(value) + self.postfix;
                        } else {
                            return Math.floor(value);
                        }
                    },
                    from: function (value) {
                        return value;
                    },
                },
            };

            this.slider = noUiSlider.create(target, options);
            this.slider.on('update', (values, handle) => {
                if (handle == 0) {
                    this.input.val(values[handle]);
                    this.input.closest('form').trigger('change');
                }
            });

            if (this.controls) {
                $('.' + this.controls).on('change', 'input', this.setRange.bind(this));

            }

            // init preset control values
            $('.' + this.controls + ' input').trigger('change');
            // init preset slider value
            if (this.input.data('init-value')) {
                this.slider.set(this.input.data('init-value'));
            }

            this.element.data('slider', this.slider);
            this.element.data('rangeslider', this);
        }

        setRange() {
            const controls = $('.' + this.controls + ' input:checked');
            let min = 9999;
            let max = 0;

            if (controls.length) {
                controls.each((i, e) => {
                    const elem = $(e);
                    min = Math.min(min, elem.data('min'));
                    max = Math.max(max, elem.data('max'));
                });
            } else {
                min = this.min;
                max = this.max;
            }

            if ( !min ) {
                min = this.min;
            }

            if ( !max ) {
                max = this.max;
            }

            this.slider.updateOptions({
                range: {
                    min: min,
                    max: max,
                },
            });
        }
    }

    export function initSliders(container?: JQuery) {
        let sliders = $('.js-range-slider');
        if (typeof container !== 'undefined') {
            sliders = $(container).find('.js-range-slider');
        }
        sliders.each(function () {
            if (!$(this).hasClass('noUi-target') && ( !$(this).closest('.modaal__content').length || ($(this).closest('.modaal__content').length && $(this).closest('.modaal__content').is(':visible')) )) {
                let rangeslider = new RangeSlider(this);
            }
        });
    }

    export function destroySliders() {
        $('.js-range-slider').each(function () {
            let slider = $(this).data('slider');
            let rangeslider = $(this).data('rangeslider');
            if (slider) {
                $(this).removeClass('noUi-target');
                slider.destroy();
                $(this).parent().html(rangeslider.html);
            }
        });
    }

    $(function () {
        initSliders();
    });
}
