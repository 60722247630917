namespace gotoAndPlay {

    export interface IContactSettings {
        LocationInitClass?: string;
        MapInitClass?: string;
    }

    export const ContactSettings = {
        LocationInitClass: 'js-change-location',
    };

    export class Contact {

        settings: IContactSettings;
        element: JQuery;

        constructor(target: HTMLElement, settings: IContactSettings = {}) {
            this.settings = jQuery.extend(ContactSettings, settings) as IContactSettings;
            this.element = $(target);
        }

        changeLocation(): void {
            const locationInput = this.element.find('select.form-textfield__input');
            const selectedLocation = locationInput.val();

            $(document).find('.contact__location[data-location="' + selectedLocation + '"]').addClass('is-visible').siblings().removeClass('is-visible');
        }
    }

    function onChange(event: JQueryEventObject): void {
        event.preventDefault();
        const contact: Contact = new Contact(this);
        contact.changeLocation();
    }

    function onInit(): void {
        $(document).on('change', '.' + ContactSettings.LocationInitClass, onChange);
    }

    $(document).ready(onInit);
}
