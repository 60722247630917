// import('import/js/progressbar.js');

namespace gotoAndPlay {

    export class RecipeList {

        element: string;
        line: JQuery;
        checkInput: string;
        checkCount: number;
        checkedCount: number;

        constructor(target: HTMLElement) {
            this.element = '.recipe-list__progress-bar';

            this.init();
        }

        init(): void {
            if ( $(document).find(this.element).length ) {
                this.line = new ProgressBar.Line(this.element, {
                    color: '#E2051B',
                    strokeWidth: 1,
                    trailColor: '#EDEAE2',
                });
            }

        }

        update(): void {
            this.checkInput = '.recipe-list__check .form-check__input';
            this.checkCount = $('.recipe-list__list').find(this.checkInput).length;
            this.checkedCount = $('.recipe-list__list').find(this.checkInput + ':checked').length;
            this.line.animate(this.checkedCount / this.checkCount);
            $('.recipe-list__percentage').text(Math.floor((this.checkedCount / this.checkCount) * 100));

        }
    }

    let recipeList;
    function onInit(): void {
        recipeList = new RecipeList(this);
    }

    function onChange(): void {
        recipeList.update();
    }

    $(document).ready(onInit);
    $(document).on('change', '.recipe-list__check .form-check__input', onChange);
}
