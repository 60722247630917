namespace gotoAndPlay {

    export class Reel {

        picX: number;
        picY: number;
        picW: number;
        picH: number;

        centerX: number;
        centerY: number;
        movestop: number;

        size: number;

        element: HTMLElement;

        constructor(target: HTMLElement) {
            this.element = target;
            $(this.element).show();
            this.size = $(this.element).find('li').length;

            this.picX = $(this.element).offset().left;
            this.picY = $(this.element).offset().top;
            this.picW = ($(this.element).width() / 2);
            this.picH = ($(this.element).height() / 2);

            this.centerX = (this.picX + this.picW);
            this.centerY = (this.picY + this.picH);
            this.movestop = (this.picW / 10);

            this.init();
        }

        init() {
            let object = this;
            $(this.element).find('li').eq(0).show().siblings().hide();

            $(this.element).on('mousemove', function(event) {
                object.handleMouseover(event);
            });

            $(this.element).on('touchmove', function(event) {
                object.handleTouchover(event);
            });
        }

        handleTouchover(event) {
            let touch = event.originalEvent.changedTouches[0];
            let mouseX = touch.pageX;
            let mouseY = touch.pageY;

            if ((mouseX - this.centerX) <= 0) {
                this.moveImg(mouseX, mouseY, 'left');
            } else {
                this.moveImg(mouseX, mouseY, '');
            }
        }

        handleMouseover(event) {
            let mouseX = event.pageX;
            let mouseY = event.pageY;
            if ((mouseX - this.centerX) <= 0) {
                this.moveImg(mouseX, mouseY, 'left');
            } else {
                this.moveImg(mouseX, mouseY, '');
            }
        }

        moveImg(mX, mY, dir) {
            let index = Math.ceil(Math.abs(mX - this.centerX) / this.movestop);
            if (dir) {
                $(this.element).find('li').eq(index).show().siblings().hide();
            } else {
                $(this.element).find('li').eq(17 - index).show().siblings().hide();
            }
        }

    }

    export function initReel(parent: JQuery) {
        parent.$content.find('.reel__list').each( function (index, element: HTMLElement) {
            let newReel = new Reel(element);
        });
    }

}
