namespace gotoAndPlay {
    export function openPopup(href: string, intent: string = 'intent') {
        const windowOptions = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes';
        const width = 550;
        const height = 420;
        const winHeight = screen.height;
        const winWidth = screen.width;
        const left = Math.round((winWidth / 2) - (width / 2));
        let top = 0;

        if (winHeight > height) {
            top = Math.round((winHeight / 2) - (height / 2));
        }

        window.open(href, 'intent', windowOptions + ',width=' + width + ',height=' + height + ',left=' + left + ',top=' + top);
    }

    $(function () {
        // TODO: Implement social shares
        $(document).on('click', '.js-share-facebook', function (event) {
            event.preventDefault();
            openPopup('https://www.facebook.com/sharer/sharer.php?u=' + decodeURIComponent($(this).attr('href')));
        });
        $(document).on('click', '.js-share-twitter', function (event) {
            event.preventDefault();
            openPopup('http://twitter.com/share?url=' + decodeURIComponent($(this).attr('href')));
        });
    });
}
