namespace gotoAndPlay {
    export class Faq {

        public element: JQuery;
        public categorySelect: JQuery;
        public categoryLink: JQuery;

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.categorySelect = this.element.find('.js-faq-category-select');
            this.categoryLink = this.element.find('.js-faq-category-link');

            this.bindEvents();
            this.onLoadHashCheck();
        }

        bindEvents() {
            $(this.categorySelect).on('change', this.onChange.bind(this));
            $(this.categoryLink).on('click', this.onClick.bind(this));
            $(document).on('click', '.' + AccordionSettings.ControlClass, this.onAccordionControlClick.bind(this));
        }

        onLoadHashCheck(): void {
            console.log( window.location.hash );
            if (window.location.hash && $(window.location.hash).length && $(window.location.hash).hasClass(AccordionSettings.AccordionClass)) {
                let currentCategory = $(window.location.hash).parents('.faq__content-item').attr('id');

                // Activate correct content section
                $('.faq__content-item').removeClass('is-current');
                $('#' + currentCategory).addClass('is-current');

                // Select correct category item
                $('.faq__category').removeClass('is-current');
                $('.faq__category-link[href="#' + currentCategory + '"]').parents('.faq__category').addClass('is-current');

                $(this.categorySelect).find('option').prop('selected', false);
                $(this.categorySelect).find('option[value="' + currentCategory + '"]').prop('selected', 'selected');
            }
        }

        onAccordionControlClick(event: JQueryEventObject): void {
            let hash = $(event.currentTarget).attr('href');
            if (hash) {
                window.history.replaceState({}, document.title, window.location.pathname + hash);
            }
        }

        onChange(event: JQueryEventObject): void {
            let selectedCategory = $(event.target).val();

            // Change links current element
            $('.faq__category').removeClass('is-current');
            $('.faq__category-link[href="#' + selectedCategory + '"]').parents('.faq__category').addClass('is-current');

            // Change content current element
            $('.faq__content-item').removeClass('is-current');
            $('#' + selectedCategory).addClass('is-current');
        }

        onClick(event: JQueryEventObject): void {
            event.preventDefault();

            // Change links current element
            $('.faq__category').removeClass('is-current');
            $(event.target).parents('.faq__category').addClass('is-current');

            // Change content current element
            let selectedCategory = $(event.target).attr('href');
            $('.faq__content-item').removeClass('is-current');
            $(selectedCategory).addClass('is-current');

            // Change content category select value
            $(this.categorySelect).find('option').prop('selected', false);
            $(this.categorySelect).find('option[value="' + selectedCategory.split('#').pop() + '"]').prop('selected', 'selected');
        }
    }

    // Init on document ready
    $(function() {
        $('.faq').each(function() {
            const faq = new Faq(this);
        });
    });
}
