namespace gotoAndPlay {
    export class BottomNav {

        bottomNav: HTMLElement;

        constructor(target: HTMLElement) {
            this.bottomNav = target;
            this.init();
        }

        init() {
            let object = this;
            if ($(window).scrollTop() == 0) {
                $(this.bottomNav).height(0);
            }

            $(document).on('scroll.bottomNav', function () {
                if ($(window).scrollTop() == 0) {
                    $(object.bottomNav).height(0);
                } else {
                    $(object.bottomNav).height('56px');
                }
            });
        }
    }

    // Init on document ready
    $(function() {
        $('.js-bottomNav').each(function() {
            const filter = new BottomNav(this);
        });
    });
}
