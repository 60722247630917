// import('import/js/jquery.easing.1.3.js');
// import('import/js/select.js');

namespace Materialize {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    export function guid() {
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }
}

namespace gotoAndPlay {

    function getSelects() {
        let materialSelect;
        if (!Helpers.isMobileDevice) {
            materialSelect = $('select').not(':disabled, .form-textfield__input--default, [data-default]');
        } else {
            materialSelect = $('.form-select--enhance select');
        }

        return materialSelect;
    }

    export function destroySelects() {
        let materialSelect = getSelects();
        materialSelect.material_select('destroy');
    }

    export function initSelects() {
        destroySelects();
        let materialSelect = getSelects();
        if (materialSelect.length) {
            materialSelect.each(function () {
                if ($(this).is(':visible')) {
                    $(this).material_select();
                }
            });
        }
    }

    $(document).on('gform_post_render', function (event) {
        initSelects();
        initCheckfields();
        initDatePickers();
        TrainingHeader.updateModal();
    });

    $(document).ready(initSelects);
}
