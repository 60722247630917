namespace gotoAndPlay {

    interface IHeaderSettings {
        toggleClass?: string;
        subnavClass?: string;
        navlinkClass?: string;
        navItemClass?: string;
        menuOpenClass?: string;
        openClass?: string;
        actionsClass?: string;
        ddActiveClass?: string;
        ddOpenClass?: string;
        actionsContainer?: string;
        hasddClass?: string;
        cartToggle?: string;
        headerClass?: string;
        childMenuClass?: string;
        backToggle?: string;
        headerFillClass?: string;
        menuLoginClass?: string;
        hiddenClass?: string;
        overlayTrigger?: string;
    }

    /**
     * @brief      Nav toggle class
     */
    export class Header {

        element: JQuery;
        mobileToggle: JQuery;
        siteBody: JQuery;
        overlay: JQuery;
        subNav: JQuery;
        actionMenu: JQuery;
        settings: IHeaderSettings;
        document: JQuery;
        window: JQuery;
        backToggle: JQuery;
        loginModal: JQuery;
        loginItem: JQuery;
        desk: boolean;
        scrollDirection: string;
        hasBeenTrigged: boolean;
        lastY: number;
        loginTrigger: JQuery;

        constructor(target: HTMLElement) {
            this.settings = {
                menuOpenClass: 'h-menu-open',
                ddOpenClass: 'h-dd-open',
                cartToggle: 'header__cart-toggle',
                hasddClass: 'has-dropdown',
                openClass: 'is-open',
                hiddenClass: 'is-hidden',
                ddActiveClass: 'has-dropdown-open',
                toggleClass: 'header__toggle',
                subnavClass: 'has-subnav',
                headerClass: 'header',
                navItemClass: 'navigation__item',
                navlinkClass: 'navigation__link',
                overlayTrigger: 'js-overlay-hover',
                childMenuClass: 'navigation__list--megamenu',
                backToggle: 'navigation__back',
                headerFillClass: 'header--filled',
                menuLoginClass: 'navigation__item--login',
            };

            this.element = $(target);
            this.siteBody = $('body');
            this.document = $(document);
            this.window = $(window);
            this.mobileToggle = $('.' + this.settings.toggleClass);
            this.subNav = $('.' + this.settings.subnavClass);
            this.actionMenu = $('.' + this.settings.overlayTrigger);
            this.desk = false;
            this.lastY = this.window.scrollTop();
            this.backToggle = $('.' + this.settings.backToggle);
            this.hasBeenTrigged = false;
            this.loginTrigger = $('.' + this.settings.menuLoginClass);
            this.loginModal = $('.header__actions').find('.header__actions-item--login');
            this.loginItem = this.loginModal.find('.header__actions-item-modal');

            this.init();
        }

        init(): void {
            const theObj = this;

            if (window.innerWidth > 1023) {
                this.desk = true;
            }

            this.heightCalculation();

            $(window).resize(() => {
                this.desk = false;

                if (window.innerWidth > 1023) {
                    this.desk = true;
                }

                this.heightCalculation();
            });

            $(document).on('click', '.js-search-open.is-closed', this.toggleSearch.bind(this));
            $(document).on('click', '.js-search-close', this.toggleSearch.bind(this));
            this.loginTrigger.on('click', this.triggerLogin.bind(this));

            this.mobileToggle.on('click', Header.toggleMobileMenu.bind(this));

            // $('.' + this.settings.cartToggle).on('click', theObj.toggleDropdown.bind(theObj));

            this.backToggle.on('click', this.toggleBack.bind(this));

            // this.document.on('click', this.resetOpened.bind(this));

            $(this.actionMenu).each((index, element) => {
                if (theObj.desk) {
                    $(element).on('mouseenter', (event) => {
                        $('body').addClass('has-overlay');
                    });
                    $(element).on('mouseleave', (event) => {
                        if (!$(event.target).is(':focus')) {
                            $('body').removeClass('has-overlay');
                            $('.header__actions-item-modal').removeClass('is-fixed');
                        }
                    });
                }
            });

            $(this.subNav).each((index, item) => {
                if (this.desk) {
                    if ($(item).parents('.navigation__item').length == 0) {
                        this.setPosition($(item));
                        $(item).on('mouseenter', this.toggleNav.bind(this));
                        $(item).on('mouseleave', this.resetMenu.bind(this));
                    }
                }
            });

            $('.header__navigation--mobile .navigation__item').each((index, item) => {
                $(item).on('click', this.toggleNav.bind(this));
            });

            this.window.on('scroll', this.scrollHead.bind(this));

        }

        heightCalculation(): void {
            if (!this.desk) {
                $('.header__inner').css('height', window.innerHeight);
            } else {
                $('.header__inner').removeAttr('style');
            }
        }

        toggleSearch(event): void {
            if (!this.siteBody.hasClass('search-open')) {
                $('.js-search-open').removeClass('is-closed');
                this.siteBody.addClass('search-open');
                setTimeout(() => {
                    $('.js-search-submit').prop('type', 'submit');
                }, 350);
            } else {
                $('.js-search-open').addClass('is-closed');
                this.siteBody.removeClass('search-open');
                $('.js-search-autosuggest-results').removeClass('is-open');
                $('.js-search-submit').prop('type', 'button');
            }
        }

        triggerLogin(): void {
            console.log('triggerLogin');
            if (this.loginItem.hasClass(this.settings.hiddenClass)) {
                this.loginModal.removeClass(this.settings.hiddenClass);
                this.loginItem.removeClass(this.settings.hiddenClass);
            } else {
                this.loginModal.addClass(this.settings.hiddenClass);
                this.loginItem.addClass(this.settings.hiddenClass);
            }
        }

        resetLogin(): void {
            const self = this;
            if (!this.loginItem.hasClass(this.settings.hiddenClass)) {
                setTimeout(() => {
                    self.loginItem.addClass(self.settings.hiddenClass);
                }, 400);

                this.loginModal.addClass(this.settings.hiddenClass);
            }
        }

        setPosition(item): void {
            const element = item.find('.' + this.settings.childMenuClass);
            if (element.length) {
                const elementPosition = element.offset();
                const windowWidth: number = this.window.innerWidth();
                const elementOverflow = elementPosition.left + element.innerWidth() - windowWidth;

                if (elementOverflow > 0) {
                    // element.css('left', ( elementOverflow * -1 ) - 208 );
                    element.css('left', ( elementPosition.left * -1 ) + ( (windowWidth - element.innerWidth()) / 2 ) );
                }
            }
        }

        static toggleMobileMenu(event): void {
            event.stopPropagation();
            event.preventDefault();

            if (!$('body').hasClass(header.settings.menuOpenClass)) {
                Helpers.disableScroll();
                $('body').addClass(header.settings.menuOpenClass);
            } else {
                Helpers.enableScroll();
                $('body').removeClass(header.settings.menuOpenClass);

                header.resetLogin();
            }
        }

        toggleNav(event?: JQueryEventObject): void {
            event.stopPropagation();

            if ($(event.currentTarget).hasClass('has-subnav')) {
                setTimeout(() => {
                    $('.header__inner').scrollTop(0);
                }, 0);
            }

            if ($(event.currentTarget).find('.navigation__link').attr('href') === '#') {
                event.preventDefault();
                let scrollPos = $(event.target).parents('.header__inner').scrollTop();

                if ($(event.target).parents('.navigation').hasClass('header__navigation--mobile')) {
                    // $(event.target).parent('.navigation__item').find('.navigation__list').css({top: scrollPos + 'px'});
                    $(event.target).parents('.header__inner').addClass('is-submenu-open');
                }

                const self = $(event.currentTarget);

                self.addClass(this.settings.openClass);

                // Add parent open class too
                $(event.target).parents('.navigation__list').addClass('is-open-parent');

                if (self.length && this.desk) {
                    self.addClass(this.settings.openClass);
                    this.siteBody.addClass(this.settings.menuOpenClass);
                }
            }
        }

        toggleBack(event): void {
            event.stopPropagation();
            event.preventDefault();

            const navList = $(event.target).closest('.' + this.settings.openClass);

            if (!$(event.target).find('.header__actions-item-modal').hasClass('is-hidden')) {
                $('.header__actions-item-modal').addClass('is-hidden');
            }

            $(event.target).parents('.header__inner').removeClass('is-submenu-open');

            navList.removeClass(this.settings.openClass);

            // Remove parent open class too
            $(event.target).closest('.navigation__list.is-open-parent').removeClass('is-open-parent');
        }

        closeDropdown(): void {
            this.siteBody.removeClass(this.settings.ddOpenClass);
            $('.' + this.settings.ddActiveClass).removeClass(this.settings.ddActiveClass);

            Helpers.enableScroll();
        }

        resetMenu(event): void {
            const navItem = $(event.target).closest('.' + this.settings.navItemClass);

            this.siteBody.removeClass(this.settings.menuOpenClass);
            navItem.removeClass(this.settings.openClass);

            this.resetLogin();

            Helpers.enableScroll();
        }

        resetOpened(event): void {
            const ddMenu = $(event.target).closest(this.settings.headerClass);

            if (!ddMenu.length) {
                this.closeDropdown();
                this.resetMenu(event);
            }

            Helpers.enableScroll();
        }

        scrollHead(): void {
            // get current scroll position

            const currY = this.window.scrollTop();

            if (currY >= 10 && !this.hasBeenTrigged) {
                this.hasBeenTrigged = true;
                $('.' + this.settings.headerClass).addClass(this.settings.headerFillClass);
            }
            if (currY < 10) {
                $('.' + this.settings.headerClass).removeClass(this.settings.headerFillClass);
                this.hasBeenTrigged = false;
            }

            // determine current scroll direction
            const y = (currY > this.lastY) ? 'down' : ((currY === this.lastY) ? 'none' : 'up');

            if (y == 'down' && currY >= 70) {
                $('.header').addClass('header--hidden');
                $('.header').removeClass('header--fixed');
                $('.header__brag-bar').addClass('header--hidden');
            } else if (y == 'up') {
                $('.header').removeClass('header--hidden');
                $('.header').addClass('header--fixed');
                $('.header__brag-bar').removeClass('header--hidden');
            }

            // update last scroll position to current position
            this.lastY = currY;
        }
    }

    // Init on document ready
    let header: Header;
    $(function () {
        $('.js-menu').each(function () {
            header = new Header(this);
        });
    });
}
