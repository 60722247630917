/* tslint:disable */
declare let Vimeo: any;
/* tslint:enable */

namespace gotoAndPlay {

    export interface IVideoSettings {
        IframeClass?: string;
    }

    export class Hero {

        static initSelector: string = '.hero';

        settings: IVideoSettings;
        element: JQuery;
        iframe: JQuery;
        player: any;

        constructor(target: HTMLElement) {
            this.settings     = {
                IframeClass: 'hero__video',
            };

            this.element = $(target);
            this.iframe = this.element.find(this.settings.IframeClass).length ? this.element.find(this.settings.IframeClass) : this.element.find('iframe');

            if (this.iframe) {
                this.initVimeo();
            }
        }

        init(): void {
            // Do nothing
        }

        initVimeo(): void {
            if (!gotoAndPlay.vimeoApiLoaded) {
                this.loadVimeoAPI().done(() => {
                    gotoAndPlay.vimeoApiLoaded = true;
                    this.play();
                });
            } else {
                this.play();
            }
        }

        loadVimeoAPI(): JQueryXHR {
            return Ajax.loadScript('https://player.vimeo.com/api/player.js');
        }

        play(): void {
            if (gotoAndPlay.vimeoApiLoaded) {
                try {
                    this.player = new Vimeo.Player(this.iframe);
                    this.player.setVolume(0);

                    setTimeout(() => {
                        this.player.play();
                    }, 1000);
                } catch (error) {
                    this.player = null;
                }
            }
        }
    }

    $(document).on('enhance.hero', function(event) {
        $(event.target).find(Hero.initSelector).addBack(Hero.initSelector).each((index: number, element: HTMLElement) => {
            let hero = $(element);
            hero.data('heroClass', new Hero(element));
        });
    });

    $(() => {
        $(document).trigger('enhance.hero');
    });
}
